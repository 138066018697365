const body = $('body');

//Open
body.on('click', '.wtp-dropdown .button', function(e) {
    //Stop default
    e.preventDefault();

    // Stop further events
    e.stopPropagation();

    // Skip if dropdown disabled
    if($(this).closest(".input").hasClass("disabled") || $(this).parent(".wtp-dropdown").hasClass("disabled")) return;

    $(".wtp-dropdown .dropdown-content ul .no-data").addClass("hidden");
    $('.wtp-dropdown .searchbar input').val("");

    searchDropdown($(this).closest(".wtp-dropdown"));

    //Check if currently closed or open
    if(!$(this).siblings(".dropdown-content").hasClass("open")){
        //Close other dropdowns
        $(".dropdown-content.open").slideUp(250,  function(){
            $(this).removeClass("open")
        });

        //Open
        $(this).siblings(".dropdown-content").css('display', '').addClass("open");
    } else {
        //Close
        $(".dropdown-content.open").slideUp(250,  function(){
            $(this).removeClass("open")
        });
    }
});

//Option clicked
body.on('click', '.wtp-dropdown .dropdown-content li', function(e) {
    const dropdown = $(this).parents(".wtp-dropdown");
    const selectedText = $(this).text();
    const hiddenValue = $(this).data('value');
    let buttonSelected = $(this).parents().siblings(".button").children(".selected");

    //Remove any error classes
    $(this).closest(".input").removeClass("error");

    //Set button text
    buttonSelected.text(selectedText);

    //Set hidden value
    if(typeof hiddenValue !== 'undefined' && hiddenValue !== false){
        buttonSelected.data("value", hiddenValue);
    }

    //Close dropdown menu
    $(this).parents(".dropdown-content").slideUp(250,  function(){
        $(this).removeClass("open")
    });

    //Remove any error classes
    $(this).parents(".wtp-dropdown").removeClass("error");

    // Trigger jQuery event
    dropdown.trigger('wtp-dropdown-change', [selectedText, hiddenValue]);

    e.stopPropagation();
});

//Stop dropbox closing if clicked
body.on('click', '.wtp-dropdown', function(e) {
    e.stopPropagation();
});

// Search dropdown on input
body.on('keyup', '.wtp-dropdown .searchbar input', function(e) {
    searchDropdown($(this).closest(".wtp-dropdown"));
});

//Close dropdown
$(document).on('click', function (e) {
    //Close any open dropdown menus
    $(".wtp-dropdown .dropdown-content.open").slideUp(250,  function(){
        $(this).removeClass("open")
    });
});

function searchDropdown(input) {
    //Skip if not a searchable dropdown
    if(input.find(".dropdown-content.searchable").length < 1){
        return;
    }

    let dropdownNoContent = input.find(".dropdown-content ul .no-data");

    //Hide no content message
    dropdownNoContent.addClass("hidden");

    //Search query
    let value = input.find(".searchbar input").val().toLowerCase().trim();

    let found = 0;
    input.find(".dropdown-content ul li").show().filter(function() {
        if($(this).text().toLowerCase().trim().indexOf(value) === -1){
            return true;
        } else {
            found++
            return false;
        }
    }).hide();
    if(found < 1){
        dropdownNoContent.removeClass("hidden");
    }
}