import {checkTechnologyData} from "../../functions";
import {
    duplicateLight,
    removeLight,
    setBallastOptions, setColourTemperatureOptions,
    setFixtureOptions, setLampCapOptions,
    setLengthOptions, setQuantityOptions,
    setTubeTypeOptions, setUpgradeTypeOptions,
    setWattageOptions, saveLight
} from "../../api/lighting";

let body = $("body");

/**
 * Reset form and close
 */
function resetLightingForm(){
    //Remove any masks on the action bar
    $(".actions-bar-container .error-mask").removeClass("active");

    $(".input#roomReference").hide();
    $(".input input#roomReference").val("");
    $("#enterRoomReference").prop("checked", false);

    $("#emergencyQuantity").hide();
    $("#emergencyQuantity").prop('disabled', false);
    $("#emergencyQuantity").val("");


    $(".dropdown#fittingType .button .selected").text("Select an option");

    $(".dropdown#fixtureType").closest(".input").addClass("disabled");
    $(".dropdown#fixtureType .button .selected").text("Select an option");

    $(".dropdown#tubeType").closest(".input").addClass("disabled");
    $(".dropdown#tubeType .button .selected").text("Select an option");

    $(".dropdown#length").closest(".input").addClass("disabled");
    $(".dropdown#length .button .selected").text("Select an option");

    $(".dropdown#ballast").closest(".input").addClass("disabled");
    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#lampCapType").closest(".input").addClass("disabled");
    $(".dropdown#lampCapType .button .selected").text("Select an option");

    $(".dropdown#wattage").closest(".input").addClass("disabled");
    $(".dropdown#wattage .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");


    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled");
    emergencyQuantity.val("").prop("disabled", true);
    $('#emergencyQuantityToggle').prop('checked', false);

    $(".lightingForm .fluoro-only").slideUp("slow");
    $(".lightingForm .not-fluoro").slideUp("slow");

    //Sensors and detectors

    //Uncheck toggles
    $("#presenceDetectorsQuantityToggle, #daylightSensorQuantityToggle").prop('checked', false);

    //Hide and reset quantity fields
    $("#daylightSensorQuantity, #presenceDetectorsQuantity").hide().val("");

    //Hide room type
    $(".roomTypeInput").hide();

    //Reset room type
    $("#roomType .button .selected").text("Select an option");


    $(".usage-table input").val("");
    if($("#lightsList tbody tr").length > 0) {
        $(".listNoData").remove();
    }

    $("#workWeeks").val(52);
}

function updateLightingForm() {
    const fittingType = $(".dropdown#fittingType .button .selected").text();
    const fixtureType = $(".dropdown#fixtureType .button .selected").text();
    const wattage = $(".dropdown#wattage .button .selected").text();
    const lampCapType = $(".dropdown#lampCapType .button .selected").text();
    const tubeType = $(".dropdown#tubeType .button .selected").text();
    const length = $(".dropdown#length .button .selected").text();
    const ballast = $(".dropdown#ballast .button .selected").text();
    const upgradeType = $(".dropdown#upgradeType .button .selected").text();

    // Set fixture options
    setFixtureOptions(fittingType);

    switch(fittingType){
        case "Fluoro-Single":
        case "Fluoro-Twin":
        case "Fluoro-Triple":
        case "Fluoro-Quad":
            // Set tube type options
            setTubeTypeOptions(fittingType, fixtureType);

            // Set length options
            setLengthOptions(fittingType, fixtureType, tubeType);

            // Set ballast options
            setBallastOptions(fittingType, fixtureType, tubeType, length);
            break;
        default:
            // Set wattage options
            setWattageOptions(fittingType, fixtureType);

            // Set lamp cap options
            setLampCapOptions(fittingType, fixtureType, wattage);
            break;
    }

    // Set upgrade type options
    setUpgradeTypeOptions(fittingType, fixtureType, tubeType, length, wattage, lampCapType, ballast);

    // Set quantity options
    setQuantityOptions(fittingType, fixtureType, tubeType, length, ballast, wattage, lampCapType, upgradeType).then(function() {
        if($(".emergencyQuantityInput").val()){
            $("#emergencyQuantityToggle").click();
        }
    });

    // Set colour temperature options
    setColourTemperatureOptions(fittingType, fixtureType, tubeType, length, ballast, wattage, lampCapType, upgradeType);
}

function lightsModalSerialize(){
    let data = {};

    data["roomName"] = $(".input input#roomReference").val();

    //Standard Row
    data["fittingType"] = $(".dropdown#fittingType .button .selected").text();
    data["fixtureType"] = $(".dropdown#fixtureType .button .selected").text();

    //Floro
    data["tubeType"] = $(".dropdown#tubeType .button .selected").text();
    data["length"] = $(".dropdown#length .button .selected").text();
    data["ballast"] = $(".dropdown#ballast .button .selected").text();

    //Not Floro
    data["wattage"] = $(".dropdown#wattage .button .selected").text();
    data["lampCapType"] = $(".dropdown#lampCapType .button .selected").text();

    // Standard Row
    data["upgradeType"] = $(".dropdown#upgradeType .button .selected").text();
    data["standardQuantity"] = $("#standardQuantity").val();
    data["emergencyQuantity"] = $("#emergencyQuantity").val();
    data["colourTemperature"] = $(".dropdown#colourTemperature .button .selected").text();

    //Sensors and detectors
    if($("#daylightSensorQuantityToggle").prop("checked")){
        data["daylightSensors"] = parseInt($("#daylightSensorQuantity").val());
    } else {
        data["daylightSensors"] = 0;
    }
    if($("#presenceDetectorsQuantityToggle").prop("checked")){
        data["presenceDetectors"] = parseInt($("#presenceDetectorsQuantity").val());
    } else {
        data["presenceDetectors"] = 0;
    }
    data["roomType"] = $(".dropdown#roomType .button .selected").data("value");

    //Daily Usage
    data["usage"] = {};
    data["usage"]["weekdays"] = {};
    data["usage"]["weekend"] = {};
    data["workweeks"] = $("input#workWeeks").val();

    //Weekdays
    data["usage"]["weekdays"]["day"] = $("#mon-fri-day-rate").val();
    data["usage"]["weekdays"]["night"] = $("#mon-fri-night-rate").val();

    //Weekend
    data["usage"]["weekend"]["day"] = $("#sat-sun-day-rate").val();
    data["usage"]["weekend"]["night"] = $("#sat-sun-night-rate").val();

    switch(data["fittingType"]){
        case "Fluoro-Single":
        case "Fluoro-Twin":
        case "Fluoro-Triple":
        case "Fluoro-Quad":
            data["fittingCode"] = data["fittingType"] + " " + data["tubeType"] + " " + data["length"] + " " + data["ballast"] + " 0 " + data["fixtureType"];
            break;
        default:
            if(data["lampCapType"] === "Not applicable"){
                data["fittingCode"] = data["fittingType"] + " " + data["wattage"] + " " + data["fixtureType"];
            } else {
                data["fittingCode"] = data["fittingType"] + " " + data["wattage"] + " " + data["lampCapType"] + " " + data["fixtureType"];
            }
            break;
    }

    return data;
}

function duplicateAndSetForm(id, keepID = false){
    duplicateLight(id).then(function(response) {
        let fields = response.data.fields;

        if (keepID) {
            $(".embed-form #updateLight").data("light-id", id);
        }

        // Open embed form
        $(".lightingForm.embed-form").slideDown("slow");
        $(".listNoData").slideDown("slow");

        // Remove field wizard classes
        $(".embed-form .input.next").removeClass("next");
        $(".embed-form .input.disabled").removeClass("disabled");

        // Insert data into fields
        $(".dropdown#fittingType .button .selected").text(fields.fittingType);
        $(".dropdown#fixtureType .button .selected").text(fields.fixtureType);
        $(".dropdown#upgradeType .button .selected").text(fields.upgradeType);
        $(".dropdown#tubeType .button .selected").text(fields.tubeType);
        $(".dropdown#length .button .selected").text(fields.length);
        $(".dropdown#wattage .button .selected").text(fields.wattage);
        $(".dropdown#ballast .button .selected").text(fields.ballast);
        $(".dropdown#lampCapType .button .selected").text(fields.lampCapType);

        $(".dropdown#colourTemperature .button .selected").text(fields.colourTemperature);
        $("#standardQuantity").val(fields.standardQuantity);

        // Insert usage table data
        $("#workWeeks").val(fields.workweeks);
        $(".usage-table tbody #mon-fri-day-rate").val(fields.usage.weekdays.day);
        $(".usage-table tbody #mon-fri-night-rate").val(fields.usage.weekdays.night);
        $(".usage-table tbody #sat-sun-day-rate").val(fields.usage.weekend.day);
        $(".usage-table tbody #sat-sun-night-rate").val(fields.usage.weekend.night);

        // Emergency quantity
        if(fields.emergencyQuantity !== 0){
            $('#emergencyQuantityToggle').prop('checked', true);
            $("#emergencyQuantity").show();
            $("#emergencyQuantity").prop('disabled', false);
            $("#emergencyQuantity").val(fields.emergencyQuantity);
        }

        // Room name field
        if(fields.roomName !== ""){
            $('#enterRoomReference').prop('checked', true);
            $("#roomReference").show();
            $("input[type=text]#roomReference").prop('disabled', false);
            $("input[type=text]#roomReference").val(fields.roomName);
        }

        // Show the relevant fields
        switch(fields.fittingType){
            case "Select an option":
                $(".not-fluoro").fadeOut("slow")
                $(".fluoro-only").fadeOut("slow")
                $(".dropdown#fixtureType").closest(".input").addClass("disabled").removeClass("next");
                $(".dropdown#fittingType").closest(".input").addClass("next");
                break;
            case "Fluoro-Single":
            case "Fluoro-Twin":
            case "Fluoro-Triple":
            case "Fluoro-Quad":
                $(".fluoro-only").fadeIn("slow")
                $(".not-fluoro").fadeOut("slow")
                $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
                break;
            default:
                $(".fluoro-only").fadeOut("slow")
                $(".not-fluoro").fadeIn("slow")
                $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
                break;
        }

        updateLightingForm();
    })
}

/**
 * Reset form and open
 */
body.on('click', '#addLight', function(e) {
    //Clear inputs
    resetLightingForm();

    //Set embed form to create mode
    $(".create-lighting-specific").show();
    $(".edit-lighting-specific").hide();

    //Open embed form
    $(".lightingForm.embed-form").slideDown("slow");
    $(".listNoData").slideDown("slow");
});

/**
 * Save unit
 */
body.on('click', '#saveLight', function(e) {
    e.preventDefault();

    let light = lightsModalSerialize();
    let errors = [];

    if(light.fixtureType === "Select an option" && light.fittingType !== "Select an option"){
        errors.push($("#fixtureType").closest(".input"));
    }

    switch(light.fittingType){
        case "Select an option":
            errors.push($("#fittingType").closest(".input"));
            break;
        case "Fluoro-Single":
        case "Fluoro-Twin":
        case "Fluoro-Triple":
        case "Fluoro-Quad":
            if(light.tubeType === "Select an option"){
                errors.push($("#tubeType").closest(".input"));
            }
            if(light.length === "Select an option"){
                errors.push($("#length").closest(".input"));
            }
            if(light.ballast === "Select an option"){
                errors.push($("#ballast").closest(".input"));
            }
            break;
        default:
            if(light.wattage === "Select an option"){
                errors.push($("#wattage").closest(".input"));
            }
            if(light.lampCapType === "Select an option"){
                errors.push($("#lampCapType").closest(".input"));
            }
            break;
    }

    if(light.fixtureType === "Select an option"){
        errors.push($("#fixtureType").closest(".input"));
    }

    if(light.upgradeType === "Select an option"){
        errors.push($("#upgradeType").closest(".input"));
    }

    //Sensors and detectors
    if(light.daylightSensors < 0 || light.daylightSensors === ""){
        errors.push($("#daylightSensorQuantity").closest(".input"));
    }
    if(light.presenceDetectors < 0 || light.presenceDetectors === ""){
        errors.push($("#presenceDetectorsQuantity").closest(".input"));
    }
    if((light.presenceDetectors !== 0 || light.daylightSensors!== 0) && (typeof light.roomType === "undefined" || light.roomType === "" || light.roomType === 0)){
        errors.push($("#roomType").closest(".input"));
    }

    //Daily usage
    if((light.standardQuantity === "0" || light.standardQuantity === "") && (light.emergencyQuantity === "0" || light.emergencyQuantity === "")){
        errors.push($("#standardQuantity").closest(".input"));
    }

    if(light.colourTemperature === "Select an option"){
        errors.push($("#colourTemperature").closest(".input"));
    }

    //Add up all of the usage inputs
    let totalUsage = $("input#mon-fri-day-rate").val() ?? 0 + $("input#mon-fri-night-rate").val() ?? 0 + $("input#sat-sun-day-rate").val() ?? 0 + $("input#sat-sun-night-rate").val() ?? 0;

    if(totalUsage === 0){
        errors.push($(".usage-table"));
    }

    if(errors.length > 0){
        alert("There are required fields which need to be filled out.");
    } else {
        light["action"] = "addLight";
        if(light.standardQuantity === ""){
            light.standardQuantity = 0;
        }
        if(light.emergencyQuantity === ""){
            light.emergencyQuantity = 0;
        }

        saveLight(light).then(function(response) {
            $("#lightsList tbody").append(response?.data?.html);
            $(".lightingForm.embed-form").slideToggle("slow");
            resetLightingForm();
            checkTechnologyData();
        })
    }
});

// Update light modal
body.on('click', '#updateLight', function(e) {
    e.preventDefault();
    let light = lightsModalSerialize();
    let errors = [];

    if(light.fixtureType === "Select an option" && light.fittingType !== "Select an option"){
        errors.push($("#fixtureType").closest(".input"));
    }

    switch(light.fittingType){
        case "Select an option":
            errors.push($("#fittingType").closest(".input"));
            break;
        case "Fluoro-Single":
        case "Fluoro-Twin":
        case "Fluoro-Triple":
        case "Fluoro-Quad":
            if(light.tubeType === "Select an option"){
                errors.push($("#tubeType").closest(".input"));
            }
            if(light.length === "Select an option"){
                errors.push($("#length").closest(".input"));
            }
            if(light.ballast === "Select an option"){
                errors.push($("#ballast").closest(".input"));
            }
            break;
        default:
            if(light.wattage === "Select an option"){
                errors.push($("#wattage").closest(".input"));
            }
            if(light.lampCapType === "Select an option"){
                errors.push($("#lampCapType").closest(".input"));
            }
            break;
    }

    if(light.fixtureType === "Select an option"){
        errors.push($("#fixtureType").closest(".input"));
    }

    if(light.upgradeType === "Select an option"){
        errors.push($("#upgradeType").closest(".input"));
    }

    if((light.standardQuantity === "0" || light.standardQuantity === "") && (light.emergencyQuantity === "0" || light.emergencyQuantity === "")){
        errors.push($("#standardQuantity").closest(".input"));
    }

    if(light.colourTemperature === "Select an option"){
        errors.push($("#colourTemperature").closest(".input"));
    }

    //Sensors and detectors
    if(light.daylightSensors < 0 || light.daylightSensors === ""){
        errors.push($("#daylightSensorQuantity").closest(".input"));
    }
    if(light.presenceDetectors < 0 || light.presenceDetectors === ""){
        errors.push($("#presenceDetectorsQuantity").closest(".input"));
    }
    if((light.presenceDetectors !== 0 || light.daylightSensors!== 0) && light.roomType === 0){
        errors.push($("#roomType").closest(".input"));
    }

    if(light.usage.weekdays.day === "0" && light.usage.weekdays.night === "0" && light.usage.weekdays.other === "0" && light.usage.weekend.day === "0" && light.usage.weekend.night === "0" && light.usage.weekend.other === "0") {
        errors.push($(".usage-table"));
    }

    if(errors.length > 0){
        alert("There are required fields which need to be filled out.");
    } else {
        if(light.standardQuantity === ""){
            light.standardQuantity = 0;
        }
        if(light.emergencyQuantity === ""){
            light.emergencyQuantity = 0;
        }

        light.id = $(".embed-form #updateLight").data("light-id");

        saveLight(light).then(function(response) {
            // Set embed form to create mode
            $(".create-lighting-specific").show();
            $(".edit-lighting-specific").hide();

            // Remove old version
            if (typeof light.id !== "undefined" && light.id !== null){
                $("#lightsList .deleteLightingRow[data-id=" + light.id + "]").closest("tr").remove();
            }

            // Add new entry
            $("#lightsList tbody").append(response?.data?.html);

            // Toggle embed form
            $(".lightingForm.embed-form").slideToggle("slow");
            resetLightingForm();
            checkTechnologyData();

        })
    }
});

// Delete light record
body.on('click', '.deleteLightingRow', function(e) {
    let deleteRow = confirm("Are you sure you want to remove this light?");

    const unit = $(this);

    if(deleteRow === true){
        removeLight(unit.data("id")).then(function() {
            unit.closest("tr").remove();
            if($("#lightsList tbody tr").length < 1){
                $(".panel").append("<style>#lightsList thead tr th:last-child {min-width: 10px;}</style><div class=\"listNoData\"><h3>Add your existing light fixtures</h3><h4>and we will automatically find you the correct upgrades.</h4><button class=\"action\" id=\"addLight\">Add Light</button></div>")
            }
        })
    }
});

// Duplicate light record
body.on('click', '.duplicateLightingRow', function(e) {
    // Set embed form to edit mode
    $(".create-lighting-specific").show();
    $(".edit-lighting-specific").hide();

    // Close embed form (if open)
    $(".lightingForm.embed-form").slideToggle("slow");
    $(".listNoData").slideToggle("slow");

    // Clear form inputs
    resetLightingForm();

    duplicateLight($(this).data("id")).then(function(response) {
        let fields = response.data.fields;

        //Open embed form
        $(".lightingForm.embed-form").slideDown("slow");
        $(".listNoData").slideDown("slow");

        //Remove field wizard classes
        $(".embed-form .input.next").removeClass("next");
        $(".embed-form .input.disabled").removeClass("disabled");

        //Insert data into fields
        $(".dropdown#fittingType .button .selected").text(fields.fittingType);
        $(".dropdown#fixtureType .button .selected").text(fields.fixtureType);
        $(".dropdown#upgradeType .button .selected").text(fields.upgradeType);
        $(".dropdown#tubeType .button .selected").text(fields.tubeType);
        $(".dropdown#length .button .selected").text(fields.length);
        $(".dropdown#wattage .button .selected").text(fields.wattage);
        $(".dropdown#ballast .button .selected").text(fields.ballast);
        $(".dropdown#lampCapType .button .selected").text(fields.lampCapType);

        $(".dropdown#colourTemperature .button .selected").text(fields.colourTemperature);
        $("#standardQuantity").val(fields.standardQuantity);

        //Insert usage table data
        $("#workWeeks").val(fields.workweeks);
        $(".usage-table tbody #mon-fri-day-rate").val(fields.usage.weekdays.day);
        $(".usage-table tbody #mon-fri-night-rate").val(fields.usage.weekdays.night);
        $(".usage-table tbody #sat-sun-day-rate").val(fields.usage.weekend.day);
        $(".usage-table tbody #sat-sun-night-rate").val(fields.usage.weekend.night);

        //Emergency quantity
        if(fields.emergencyQuantity !== 0){
            $('#emergencyQuantityToggle').prop('checked', true);
            $("#emergencyQuantity").show();
            $("#emergencyQuantity").prop('disabled', false);
            $("#emergencyQuantity").val(fields.emergencyQuantity);
        }

        //Room name field
        if(fields.roomName !== ""){
            $('#enterRoomReference').prop('checked', true);
            $("#roomReference").show();
            $("input[type=text]#roomReference").prop('disabled', false);
            $("input[type=text]#roomReference").val(fields.roomName);
        }

        //Show the relevant fields
        switch(fields.fittingType){
            case "Select an option":
                $(".not-fluoro").fadeOut("slow")
                $(".fluoro-only").fadeOut("slow")
                $(".dropdown#fixtureType").closest(".input").addClass("disabled").removeClass("next");
                $(".dropdown#fittingType").closest(".input").addClass("next");
                break;
            case "Fluoro-Single":
            case "Fluoro-Twin":
            case "Fluoro-Triple":
            case "Fluoro-Quad":
                $(".fluoro-only").fadeIn("slow")
                $(".not-fluoro").fadeOut("slow")
                $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
                break;
            default:
                $(".fluoro-only").fadeOut("slow")
                $(".not-fluoro").fadeIn("slow")
                $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
                break;
        }

        updateLightingForm();
    });
});

// Edit light record
body.on('click', '.editLightingRow', function(e) {
    // Set embed form to edit mode
    $(".create-lighting-specific").hide();
    $(".edit-lighting-specific").show();

    // Close embed form (if open)
    $(".lightingForm.embed-form").slideToggle("slow");
    $(".listNoData").slideToggle("slow");

    // Clear form inputs
    resetLightingForm();

    // Duplicate the light and keep the same if (essentially editing the light)
    duplicateAndSetForm($(this).data("id"), true);
});

body.on('click', '.dropdown#fittingType .dropdown-content ul li', function(e) {
    const fittingType = $(".dropdown#fittingType");
    const fittingTypeValue = fittingType.find(".button .selected").text();
    const fixtureType = $(".dropdown#fixtureType");

    $(".dropdown#fixtureType .button .selected").text("Select an option");

    $(".dropdown#tubeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#tubeType .button .selected").text("Select an option");

    $(".dropdown#length").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#length .button .selected").text("Select an option");

    $(".dropdown#ballast").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#wattage").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#wattage .button .selected").text("Select an option");

    $(".dropdown#lampCapType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#lampCapType .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");



    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");

    switch($(this).text()){
        case "Select an option":
            $(".not-fluoro").fadeOut("slow")
            $(".fluoro-only").fadeOut("slow")
            fixtureType.closest(".input").addClass("disabled").removeClass("next");
            fittingType.closest(".input").addClass("next");
            return;
            break;
        case "Fluoro-Single":
        case "Fluoro-Twin":
        case "Fluoro-Triple":
        case "Fluoro-Quad":
            $(".fluoro-only").fadeIn("slow")
            $(".not-fluoro").fadeOut("slow")
            break;
        default:
            $(".fluoro-only").fadeOut("slow")
            $(".not-fluoro").fadeIn("slow")
            break;
    }


    // Fetch fixture options
    setFixtureOptions(fittingTypeValue).then(function() {
        // Highlight the dropdown
        fittingType.closest(".input").removeClass("next");
        fixtureType.closest(".input").removeClass("disabled").addClass("next");
    })
});

body.on('click', '.dropdown#fixtureType .dropdown-content ul li', function(e) {

    $(".dropdown#fixtureType").closest(".input").removeClass("next");

    $(".dropdown#tubeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#tubeType .button .selected").text("Select an option");

    $(".dropdown#length").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#length .button .selected").text("Select an option");

    $(".dropdown#ballast").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#wattage").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#wattage .button .selected").text("Select an option");

    $(".dropdown#lampCapType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#lampCapType .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");


    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");

    if($(this).text() !== "Select an option"){
        const fittingType = $(".input .dropdown#fittingType .button .selected").text();
        const fixtureType = $(this).text();

        switch(fittingType){
            case "Fluoro-Single":
            case "Fluoro-Twin":
            case "Fluoro-Triple":
            case "Fluoro-Quad":
                setTubeTypeOptions(fittingType, fixtureType).then(function() {
                    // Highlight the dropdown
                    $(".dropdown#tubeType").closest(".input").removeClass("disabled").addClass("next");
                });
                break;
            default:
                setWattageOptions(fittingType, fixtureType).then(function() {
                    // Highlight the dropdown
                    $(".dropdown#wattage").closest(".input").removeClass("disabled").addClass("next");
                });
                break;
        }
    } else {
        $(".dropdown#tubeType").closest(".input").addClass("disabled");
        $(".dropdown#tubeType .button .selected").text("Select an option");
        $(".dropdown#wattage").closest(".input").addClass("disabled");
        $(".dropdown#wattage .button .selected").text("Select an option");
        $(".dropdown#fixtureType").closest(".input").addClass("next");
    }
});

body.on('click', '.dropdown#tubeType .dropdown-content ul li', function(e) {
    const lengthDropdownSelected = $(".dropdown#length .button .selected");

    lengthDropdownSelected.text("Select an option");

    $(".dropdown#ballast").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");

    if($(this).text() !== "Select an option"){
        $(".dropdown#length").closest(".input").removeClass("disabled");

        const fittingType = $(".input .dropdown#fittingType .button .selected").text();
        const fixtureType = $(".input .dropdown#fixtureType .button .selected").text();
        const tubeType = $(this).text();

        setLengthOptions(fittingType, fixtureType, tubeType).then(function() {
            // Highlight the dropdown
            $(".dropdown#tubeType").closest(".input").removeClass("next");
            $(".dropdown#length").closest(".input").addClass("next");
        });
    } else {
        $(".dropdown#length").closest(".input").addClass("disabled");
        lengthDropdownSelected.text("Select an option");
        $(".dropdown#tubeType").closest(".input").addClass("next");
    }
});

body.on('click', '.dropdown#length .dropdown-content ul li', function(e) {
    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");

    if($(this).text() !== "Select an option"){
        $(".dropdown#ballast").closest(".input").removeClass("disabled");

        const fittingType = $(".input .dropdown#fittingType .button .selected").text();
        const fixtureType = $(".input .dropdown#fixtureType .button .selected").text();
        const tubeType = $(".input .dropdown#tubeType .button .selected").text();
        const length = $(this).text();

        // Fetch ballast options
        setBallastOptions(fittingType, fixtureType, tubeType, length).then(function() {
            // Highlight the dropdown
            $(".dropdown#length").closest(".input").removeClass("next");
            $(".dropdown#ballast").closest(".input").addClass("next");
        });
    } else {
        $(".dropdown#ballast").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#ballast .button .selected").text("Select an option");
        $(".dropdown#length").closest(".input").addClass("next");

    }
});

body.on('click', '.dropdown#ballast .dropdown-content ul li', function(e) {

    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");


    if($(this).text() !== "Select an option"){
        $(".dropdown#upgradeType").closest(".input").removeClass("disabled");

        const fittingType = $(".input .dropdown#fittingType .button .selected").text();
        const fixtureType = $(".input .dropdown#fixtureType .button .selected").text();
        const tubeType = $(".input .dropdown#tubeType .button .selected").text();
        const length = $(".input .dropdown#length .button .selected").text();
        const wattage = $(".input .dropdown#wattage .button .selected").text();
        const lampCapType = $(".input .dropdown#lampCapType .button .selected").text();
        const ballast = $(this).text();

        // Set upgrade types in dropdown
        setUpgradeTypeOptions(fittingType, fixtureType, tubeType, length, wattage, lampCapType, ballast).then(function() {
            // Highlight the dropdown
            $(".dropdown#ballast").closest(".input").removeClass("next");
            $(".dropdown#upgradeType").closest(".input").addClass("next");
        });
    } else {
        $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#ballast").closest(".input").addClass("next");
    }
});

body.on('click', '.dropdown#wattage .dropdown-content ul li', function(e) {
    $(".dropdown#lampCapType .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");


    if($(this).text() !== "Select an option"){
        $(".dropdown#lampCapType").closest(".input").removeClass("disabled");

        const fittingType = $(".input .dropdown#fittingType .button .selected").text();
        const fixtureType = $(".input .dropdown#fixtureType .button .selected").text();
        const wattage = $(this).text();

        // Fetch lamp cap dropdown options
        setLampCapOptions(fittingType, fixtureType, wattage).then(function() {
            // Highlight the dropdown
            $(".dropdown#wattage").closest(".input").removeClass("next");
            $(".dropdown#lampCapType").closest(".input").addClass("next");
        });
    } else {
        $(".dropdown#lampCapType").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#wattage").closest(".input").addClass("next");
    }
});

body.on('click', '.dropdown#lampCapType .dropdown-content ul li', function(e) {

    $(".dropdown#lampCapType").closest(".input").removeClass("next");
    $(".dropdown#upgradeType").closest(".input").addClass("next");


    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");


    if($(this).text() !== "Select an option"){
        $(".dropdown#upgradeType").closest(".input").removeClass("disabled");

        const fittingType = $(".input .dropdown#fittingType .button .selected").text();
        const fixtureType = $(".input .dropdown#fixtureType .button .selected").text();
        const tubeType = $(".input .dropdown#tubeType .button .selected").text();
        const length = $(".input .dropdown#length .button .selected").text();
        const wattage = $(".input .dropdown#wattage .button .selected").text();
        const lampCapType = $(".input .dropdown#lampCapType .button .selected").text();
        const ballast = $(this).text();

        setUpgradeTypeOptions(fittingType, fixtureType, tubeType, length, wattage, lampCapType, ballast).then(function() {
            // Highlight the dropdown
            $(".dropdown#ballast").closest(".input").removeClass("next");
            $(".dropdown#upgradeType").closest(".input").addClass("next");
        })
    } else {
        $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#lampCapType").closest(".input").addClass("next");
    }
})

body.on('click', '.dropdown#upgradeType .dropdown-content ul li', function(e) {
    $(".dropdown#upgradeType").closest(".input").removeClass("next");

    const fittingType = $(".input .dropdown#fittingType .button .selected").text();
    const fixtureType = $(".input .dropdown#fixtureType .button .selected").text();
    const tubeType = $(".input .dropdown#tubeType .button .selected").text();
    const length = $(".input .dropdown#length .button .selected").text();
    const ballast = $(".input .dropdown#ballast .button .selected").text();
    const wattage = $(".input .dropdown#wattage .button .selected").text();
    const lampCapType = $(".input .dropdown#lampCapType .button .selected").text();
    const upgradeType = $(this).text();

    // Fetch upgrade types available for the inputted light
    setQuantityOptions(fittingType, fixtureType, tubeType, length, ballast, wattage, lampCapType, upgradeType)

    // Fetch colour temperatures for the inputted light
    setColourTemperatureOptions(fittingType, fixtureType, tubeType, length, ballast, wattage, lampCapType, upgradeType)
});

body.on('input', 'input#standardQuantity', function(e) {
    if($(this).val()){
        $(this).closest(".input").removeClass("next");

        if($("input#emergencyQuantity").prop("disabled")){
            $(".dropdown#colourTemperature").closest(".input").removeClass("disabled").addClass("next");
        } else {
            $("input#emergencyQuantity").closest(".input").removeClass("disabled").addClass("next");
        }
    } else {
        $(this).closest(".input").addClass("next");
        $("input#emergencyQuantity").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");

    }
});

body.on('input', 'input#emergencyQuantity', function(e) {
    if($(this).val()){
        $(this).closest(".input").removeClass("next");
        $(".dropdown#colourTemperature").closest(".input").removeClass("disabled").addClass("next");

    } else {
        $(this).closest(".input").addClass("next");
        $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    }
});

body.on('click', '.dropdown#colourTemperature .dropdown-content ul li', function(e) {
    if($(this).text() !== "Select an option") {
        $(this).closest(".input").removeClass("next");
        let totalUsage = $("input#mon-fri-day-rate").val() ?? 0 + $("input#mon-fri-night-rate").val() ?? 0 + $("input#sat-sun-day-rate").val() ?? 0 + $("input#sat-sun-night-rate").val() ?? 0;
        if(totalUsage < 1){
            $(".usage-table").addClass("error");
        } else {
            $(".usage-table").removeClass("error");

        }

    } else {
        $(this).closest(".input").addClass("next");
        $(".usage-table").removeClass("error");
    }
});

//Emergency light quantity toggle
body.on('click', '#emergencyQuantityToggle', function(e) {
    if($("#emergencyQuantityToggle").prop("checked")){
        //Check to see if normal quantity field has been entered into
        if(!$("#standardQuantity").closest(".input").hasClass("disabled")){
            if($("#standardQuantity").val() != "" && $("#standardQuantity").closest(".input").hasClass("next")){

            }
            //Highlight the emergency quantity field
            $("#emergencyQuantity").closest(".input").removeClass("disabled").addClass("next");

            //Remove the highlight from the colour temperature
            $("#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
        }


        //Show emergency quantity field
        $("#emergencyQuantity").show();
        $("#emergencyQuantity").prop('disabled', false);
    } else {

        //Check to see if the emergency quantity field is highlighted
        if($("#emergencyQuantity").closest(".input").hasClass("next")){
            //Remove highlight
            $("#emergencyQuantity").closest(".input").removeClass("next");
            $("#emergencyQuantity").val("").prop("disabled", true);

            //Highlight the appropriate field
            $("#colourTemperature").closest(".input").removeClass("disabled").addClass("next");
        }


        //Hide emergency quantity field
        $("#emergencyQuantity").hide();
        $("#emergencyQuantity").prop('disabled', true);
    }

});

//Daylight sensor quantity toggle
body.on('click', '#daylightSensorQuantityToggle', function(e) {
    const presenceDetectorsQuantityToggled = $("#presenceDetectorsQuantityToggle").prop("checked");
    if($(this).prop("checked")){
        //Show quantity field
        $("#daylightSensorQuantity").show();

        $(".roomTypeInput").show();
    } else {
        //Hide quantity field
        $("#daylightSensorQuantity").hide();

        if(!presenceDetectorsQuantityToggled){
            $(".roomTypeInput").hide();
        }
    }
});

//Presence detectors quantity toggle
body.on('click', '#presenceDetectorsQuantityToggle', function(e) {
    const daylightSensorQuantityToggled = $("#daylightSensorQuantityToggle").prop("checked");
    if($(this).prop("checked")){
        //Show quantity field
        $("#presenceDetectorsQuantity").show();

        //Show building type input
        $(".roomTypeInput").show();
    } else {
        //Hide quantity field
        $("#presenceDetectorsQuantity").hide();

        if(!daylightSensorQuantityToggled){
            $(".roomTypeInput").hide();
        }
    }
});
