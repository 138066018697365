import {deleteUnit, getModeList, getStorageLoss, saveUnit} from "../api/hot-water-timer";

let body = $("body");

//Toggle open assumption fields
body.on('click', '.module-form[data-module=buildingFabric] .edit-assumptions-button', function(e) {
    const fields = $(this).closest(".input-form").find(".assumption-fields");

    //Get state of form
    const isOpen = fields.hasClass("open");

    if(isOpen){
        //Slide up
        fields.slideUp("fast", function(){
            //Close form
            fields.removeClass("open");
        });

        rotate($(this).find("svg"), 0)
    } else {
        //Slide down
        fields.slideDown("fast", function() {
            //Open form
            fields.addClass("open");

            //Clear inline style
            fields.removeAttr("style");
        });

        rotate($(this).find("svg"), 180)
    }
});

//Toggle input form
body.on('click', '.module-form[data-module=hotWaterTimer] .toggle-add-unit-form', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    //Get state of form
    const isOpen = form.hasClass("open");

    if(isOpen){
        //Slide up
        form.slideUp("slow");

        //Close form
        form.removeClass("open");
    } else {
        //Slide down
        form.slideDown("slow");

        //Open form
        form.addClass("open");
    }
});

//Close input form
body.on('click', '.module-form[data-module=hotWaterTimer] .unit-input-container .unit-input-form .cancel-button', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    //Slide up
    form.slideUp("slow");

    //Close form
    form.removeClass("open");
});

// Make value change
body.on('input', '.module-form[data-module=hotWaterTimer] .unit-input-container .unit-input-form input[name=manualMakeName]', function(e) {
    const value = $(this).val();
    if (value.trim() === ""){
        return;
    }
    const form = $(this).closest(".unit-input-form");
    const suggestions = form.find("input[name=manualModelName]").parent(".input-field").find(".input-suggestions");

    // Set loading state
    suggestions.addClass("loading");

    // Get models for the make
    getModeList(value).then(function(response){
        // Remove loading state
        suggestions.removeClass("loading");

        if (response.success && response.data && response.data.length > 0){
            const content = suggestions.find(".simplebar-content");
            let html = "";

            // Remove existing options
            content.find(".input-suggestions-option").remove();

            // Loop through models
            response.data.forEach(function(entry){
                html += `<button class="input-suggestions-option" type="button">${entry.model}</button>`;
            });

            // Add options to dropdown
            content.append(html);
        }
    })
});

body.on('input', '.module-form[data-module=hotWaterTimer] .unit-input-container .unit-input-form input[name=manualModelName]', function(e) {
    const form = $(this).closest(".unit-input-form");
    const make = form.find("input[name=manualMakeName]").val();
    const model = $(this).val();
    const input = form.find("input[name=monthlyStorageLoss]");

    if (model.trim() === "" || make.trim() === ""){
        return;
    }

    // Disable input
    input.prop("disabled", true);

    getStorageLoss(make, model).then(function(response){
        // Enable input
        input.prop("disabled", false);

        if (response.success && response.data && typeof response.data.monthly_storage_loss_mj !== "undefined"){
            // Set value
            input.val(response.data.monthly_storage_loss_mj);
        }
    });
});


// Submit unit input form
body.on('submit', '#addHotWaterTimerPlugInUnitForm, #addHotWaterTimerFusedUnitForm', function(e) {
    e.preventDefault();
    const submitButton = $(this).find("button[type=submit]");
    let form = $(this);

    const type = $(this).is("#addHotWaterTimerPlugInUnitForm") ? "plug-in" : "fused";

    // Hide errors
    form.find(".errors").hide();

    // Disable submit button
    submitButton.prop("disabled", true);

    // Serialize form data
    const formData = $(this).serializeArray();

    // Loop through form data, convert to key value object
    let data = {};
    formData.forEach(function(entry){
        data[entry.name] = entry.value;
    });

    saveUnit(
        data.manualMakeName ?? "",
        data.manualModelName ?? "",
        parseFloat(data.monthlyStorageLoss ?? 0),
        parseInt(data.quantity ?? 0),
        type
    ).then(function(response){
        // Enable submit button
        submitButton.prop("disabled", false);

        if (response.success){
            // Hide errors
            form.find(".errors").hide();

            // Clear form
            form[0].reset();

            // Close form
            form.closest(".unit-input-form").slideUp("slow");

            // Find inputted units
            const unitList = form.closest(".unit-input-container").find(".inputted-units");

            // Add unit to list
            unitList.append(`<div class="unit-card"><div class="details"><div class="row"><span class="text">${response.data.make} - ${response.data.model}</span></div><div class="row"><span class="text subtle">Quantity: ${response.data.quantity}</span><span class="text subtle">Monthly storage loss: ${response.data.storageLoss} mJ</span></div></div><div class="actions"><button data-id="${response.data.uuid}" data-type="plug-in" class="delete-unit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button></div></div>`);

            // Hide empty state
            unitList.find(".empty-state").addClass("hide")

            // Remove errors from any empty state
            $("#fusedHotWaterHeaters, #plugInHotWaterHeaters").find(".empty-state").removeClass("error");
        } else {
            const errorsContainer = form.find(".errors");

            // Loop errors and add
            let html = "";
            response.data.forEach(function(error){
                html += `<li class="error-item">${error}</li>`;
            });

            // Set errors
            errorsContainer.find(".errors-list").html(html);

            // Show errors
            errorsContainer.show();
        }
    }).catch(function(error){
        console.log(error);
    });
});

// Delete unit
body.on('click', '.module-form[data-module=hotWaterTimer] .inputted-units .unit-card .delete-unit', function(e) {
    // Confirm delete
    if(!confirm("Are you sure you want to delete this unit?")){
        return;
    }

    // Get unit card
    const unitCard = $(this).closest(".unit-card");

    // Get unit id
    const unitId = $(this).attr("data-id");

    // Get unit type
    const type = $(this).attr("data-type");

    let inputContainer = $(this).closest(".unit-input-container");

    // Remove unit card
    unitCard.remove();

    // Show empty state if needed
    if(inputContainer.find(".inputted-units .unit-card").length === 0){
        inputContainer.find(".inputted-units .empty-state").removeClass("hide");
    }

    // Delete unit
    deleteUnit(unitId, type).then(function(response){
        console.log("Unit deleted");
    });
});

// Operating hours table
body.on('change', '.module-form[data-module=hotWaterTimer] .input-operating-hours-table input', function(e) {
    const tableRow = $(this).closest("tr");
    let startTime = null;
    let endTime = null;

    // Get all inputs in row
    const inputs = tableRow.find("input");

    // Loop each input element
    inputs.each(function(index, element){
        const input = $(element);

        // Check if input is start time
        if(input.attr("name").toLowerCase().includes("start")){
            startTime = input.val();
        }

        // Check if input is end time
        if(input.attr("name").toLowerCase().includes("end")){
            endTime = input.val();
        }
    });

    if (startTime !== null && endTime !== null && startTime !== "" && endTime !== ""){
        // Get total hours
        const totalHours = calculateTotalHours(startTime, endTime);

        let multiplier = 1;

        if ($(this).attr("name").toLowerCase().includes("mondayfriday")){
            multiplier = 5;
        }

        const displayHours = Math.round(totalHours * multiplier * 100) / 100

        // Set total hours
        tableRow.find(".hour-total").text(displayHours + " hours");
    }

    // Get all total hours
    const totalHourElements = $(".module-form[data-module=hotWaterTimer] .input-operating-hours-table .hour-total");

    let totalHours = 0;
    totalHourElements.each(function(index, element){
        if ($(element).hasClass("grand-total")) return;
        const text = $(element).text();
        const hours = parseFloat(text.split(" ")[0]);
        totalHours += hours;
    });

    const annualOpenHours = Math.round(totalHours * 52 * 100) / 100;

    // Set total hours
    $(".module-form[data-module=hotWaterTimer] .grand-total").text(annualOpenHours + " hours");

    // Total standby hours
    const totalStandbyHours = Math.round(((7 * 24 - totalHours) * 52) * 100) / 100;

    $(".module-form[data-module=hotWaterTimer] #annualStandbyHours").val(totalStandbyHours);
});


function calculateTotalHours(startTime, endTime){
    const start = startTime.split(":");
    const end = endTime.split(":");

    const startHours = parseInt(start[0]);
    const startMinutes = parseInt(start[1]);

    const endHours = parseInt(end[0]);
    const endMinutes = parseInt(end[1]);

    let totalHours = 0;

    // Calculate total hours
    if (startHours === endHours){
        totalHours = endMinutes - startMinutes;
    } else {
        totalHours = (endHours - startHours) * 60;
        totalHours += endMinutes - startMinutes;
    }

    return Math.max(Math.round((totalHours / 60) * 100) / 100, 0);
}

function rotate(object, degree) {
    object.css({WebkitTransform: 'rotate(' + degree + 'deg)'});
    object.css({'-moz-transform': 'rotate(' + degree + 'deg)'});
}