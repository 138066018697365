import {apiRequest} from "./index";


/**
 * Set the fixture options for the dropdown
 * @param fittingType
 * @returns {Promise<unknown>}
 */
export function setFixtureOptions(fittingType){
    return new Promise(function(resolve, reject){
        // Fetch fixtures for fitting type
        apiRequest('lighting', 'fixtureList', {
            fittingType: fittingType,
        }).then(function(response) {
            let html = "";
            let options = response.data;

            options.unshift("Select an option");

            // Format the options
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });

            // Insert the options into the dropdown
            $(".dropdown#fixtureType .simplebar-content").html(html);

            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}


/**
 * Set the tube type options for the dropdown
 * @param {string} fittingType
 * @param {string} fixtureType
 * @returns {Promise<unknown>}
 */
export function setTubeTypeOptions(fittingType, fixtureType){
    return new Promise(function(resolve, reject){
        // Fetch fixtures for fitting type
        apiRequest('lighting', 'tubeTypeList', {
            fittingType: fittingType,
            fixtureType: fixtureType,
        }).then(function(response) {
            let options = response.data;
            let html = "";

            // Format the options
            options.forEach(function(entry) {
                if(entry === "T5"){
                    html = "<li>" + entry + "</li>" + html;
                } else {
                    html = html + "<li>" + entry + "</li>";
                }
            });
            html = "<li>Select an option</li>" + html;

            // Insert the options into the dropdown
            $(".dropdown#tubeType .simplebar-content").html(html);

            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}


/**
 * Set the wattage options for the dropdown
 * @param {string} fittingType
 * @param {string} fixtureType
 * @returns {Promise<unknown>}
 */
export function setWattageOptions(fittingType, fixtureType){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'wattageList', {
            fittingType: fittingType,
            fixtureType: fixtureType,
        }).then(function(response) {
            let html = "";
            let options = response.data;

            // Insert default option
            options.unshift("Select an option");

            // Format the options
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });

            // Insert the options into the dropdown
            $(".dropdown#wattage .simplebar-content").html(html);

            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}


/**
 * Set the length options for the dropdown
 * @param {string} fittingType
 * @param {string} fixtureType
 * @param {string} tubeType
 * @returns {Promise<unknown>}
 */
export function setLengthOptions(fittingType, fixtureType, tubeType){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'lengthList', {
            fittingType: fittingType,
            fixtureType: fixtureType,
            tubeType: tubeType,
        }).then(function(response) {
            let html = "";
            let options = response.data;

            // Insert default option
            options.unshift("Select an option");

            // Format the options
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });

            // Insert the options into the dropdown
            $(".dropdown#length .simplebar-content").html(html);

            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

/**
 * Set the ballast options for the dropdown
 * @param {string} fittingType
 * @param {string} fixtureType
 * @param {string} tubeType
 * @param {string} length
 * @returns {Promise<unknown>}
 */
export function setBallastOptions(fittingType, fixtureType, tubeType, length){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'ballastList', {
            fittingType: fittingType,
            fixtureType: fixtureType,
            tubeType: tubeType,
            length: length,
        }).then(function(response) {
            let options = response.data;
            let html = "";

            // Insert default option
            options.unshift("Select an option");

            // Format the options
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });

            // Insert the options into the dropdown
            $(".dropdown#ballast .simplebar-content").html(html);

            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

/**
 * Set the upgrade type options for the dropdown
 * @param {string} fittingType
 * @param {string} fixtureType
 * @param {string} tubeType
 * @param {string} length
 * @param {string} wattage
 * @param {string} lampCapType
 * @param {string} ballast
 * @returns {Promise<unknown>}
 */
export function setUpgradeTypeOptions(fittingType, fixtureType, tubeType, length, wattage, lampCapType, ballast){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'listUpgradeTypes', {
            fittingType: fittingType,
            fixtureType: fixtureType,
            tubeType: tubeType,
            length: length,
            wattage: wattage,
            lampCapType: lampCapType,
            ballast: ballast,
        }).then(function(response) {
            let html = "";
            let options = response.data;

            // Insert default option
            options.unshift("Select an option");

            // Format the options
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });

            // Insert the options into the dropdown
            $(".dropdown#upgradeType .simplebar-content").html(html);

            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}


/**
 * Set the lamp cap options for the dropdown
 * @param {string} fittingType
 * @param {string} fixtureType
 * @param {string} wattage
 * @returns {Promise<unknown>}
 */
export function setLampCapOptions(fittingType, fixtureType, wattage){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'lampCapList', {
            fittingType: fittingType,
            fixtureType: fixtureType,
            wattage: wattage,
        }).then(function(response) {
            let html = "";
            let options = response.data;

            // Insert default option
            options.unshift("Select an option");

            // Format the options
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });

            // Insert the options into the dropdown
            $(".dropdown#lampCapType .simplebar-content").html(html);

            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}


/**
 * Set the quantity inputs
 * @param fittingType
 * @param fixtureType
 * @param tubeType
 * @param length
 * @param ballast
 * @param wattage
 * @param lampCapType
 * @param upgradeType
 * @returns {Promise<unknown>}
 */
export function setQuantityOptions(fittingType, fixtureType, tubeType, length, ballast, wattage, lampCapType, upgradeType){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'quantityTypesList', {
            fittingType: fittingType,
            fixtureType: fixtureType,
            tubeType: tubeType,
            length: length,
            ballast: ballast,
            wattage: wattage,
            lampCapType: lampCapType,
            upgradeType: upgradeType,
        }).then(function(response) {
            let options = response.data;

            let standardQuantity = $("input#standardQuantity");
            let emergencyQuantity = $("input#emergencyQuantity");

            if(options.includes("standard")){
                standardQuantity.prop("disabled", false);
                standardQuantity.closest(".input").removeClass("disabled").addClass("next");

                emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
            } else {
                standardQuantity.prop("disabled", true);
                standardQuantity.closest(".input").addClass("disabled").removeClass("next");

                if(options.includes("emergency")){
                    emergencyQuantity.closest(".input").removeClass("disabled").addClass("next");
                } else {
                    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
                }
            }

            if(options.includes("emergency")){
                $("#emergencyQuantityToggle").removeAttr('disabled');
                if(!$(".emergencyQuantityInput").hasClass("disabled")){
                    emergencyQuantity.removeAttr('disabled');
                }
            } else {
                let toggle = $("#emergencyQuantityToggle");
                toggle.prop("disabled", true);
                toggle.prop('checked', false);
                emergencyQuantity.hide();
                emergencyQuantity.prop('disabled', true);
            }

            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

/**
 * Set the colour temperature options
 * @param {string} fittingType
 * @param {string} fixtureType
 * @param {string} tubeType
 * @param {string} length
 * @param {string} ballast
 * @param {string} wattage
 * @param {string} lampCapType
 * @param {string} upgradeType
 * @returns {Promise<unknown>}
 */
export function setColourTemperatureOptions(fittingType, fixtureType, tubeType, length, ballast, wattage, lampCapType, upgradeType){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'colourTemperaturesList', {
            fittingType: fittingType,
            fixtureType: fixtureType,
            tubeType: tubeType,
            length: length,
            ballast: ballast,
            wattage: wattage,
            lampCapType: lampCapType,
            upgradeType: upgradeType,
        }).then(function(response) {
            let html = "";
            let options = response.data;

            // Insert default option
            options.unshift("Select an option");

            // Format the options
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });

            // Insert the options into the dropdown
            $(".dropdown#colourTemperature .simplebar-content").html(html);

            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

/**
 * Remove a light from the list (detailed)
 * @param id
 * @returns {Promise<unknown>}
 */
export function removeLight(id){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'removeUnit', {
            unitId: id,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function setLightingCalculationType(type){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'setCalculationType', {
            type: type,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function saveLightingSimpleForm(formData){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'saveSimpleForm', {
            form: formData,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function saveLight(formData){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'saveLight', {
            form: formData,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function duplicateLight(id){
    return new Promise(function(resolve, reject){
        apiRequest('lighting', 'duplicateUnit', {
            unitId: id,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}