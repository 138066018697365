import { checkTechnologyData } from "../../functions";

// Detailed lighting calculations
import './detailed.js';

// Simple lighting calculations
import './simple.js';
import {setLightingCalculationType} from "../../api/lighting";

let body = $("body");


body.on('change', '.lighting-calculation-type-selector .radio-inputs input[type=radio]', function(e) {
    // Enable the next button
    $(".lighting-calculation-type-selector .submit-button").prop("disabled", false);
});

body.on('click', '.lighting-calculation-type-selector .submit-button', function(e) {
    // Hide the selector
    $(".lighting-calculation-type-selector").addClass("hide");

    // Get the selected value
    let selectedValue = $(".lighting-calculation-type-selector .radio-inputs input[type=radio]:checked").val();

    // Show switch
    $(".calculation-type-selector-switch").show();

    switch(selectedValue){
        case "detailed":
            // Show detailed calculations elements
            showDetailedLightingCalculations();
            break;
        case "simple":
            // Show simple calculations elements
            showSimpleLightingCalculations();
            break;
    }
});

body.on('change', '.calculation-type-selector-switch .switch-inputs input[type=radio]', function(e) {
    // Get the selected value
    let selectedValue = $(".calculation-type-selector-switch .switch-inputs input[type=radio]:checked").val();

    switch(selectedValue){
        case "detailed":
            // Show detailed calculations elements
            showDetailedLightingCalculations();
            break;
        case "simple":
            // Show simple calculations elements
            showSimpleLightingCalculations();
            break;
    }
});


// Show simple lighting calculations
function showSimpleLightingCalculations(){
    // Elements
    let detailedLighting = $(".detailed-lighting");
    let simpleLighting = $(".simple-lighting");


    // Hide the selector
    $(".lighting-calculation-type-selector").addClass("hide");

    // Show simple calculations elements
    simpleLighting.show();

    // Hide detailed calculations elements
    detailedLighting.hide();

    // Update switch value
    $(".calculation-type-selector-switch #simple-calculation-type-switch").prop("checked", true);
    $(".calculation-type-selector-switch #detailed-calculation-type-switch").prop("checked", false);

    // Update session variable
    setLightingCalculationType("simple").then(response => {
        checkTechnologyData();
    }).catch(error => {
        alert("Error saving lighting calculation type");
    });
}

// Show detailed lighting calculations
function showDetailedLightingCalculations(){
    // Elements
    let detailedLighting = $(".detailed-lighting");
    let simpleLighting = $(".simple-lighting");

    // Hide the selector
    $(".lighting-calculation-type-selector").addClass("hide");

    // Show detailed calculations elements
    detailedLighting.show();

    // Hide simple calculations elements
    simpleLighting.hide();

    // Update switch value
    $(".calculation-type-selector-switch #simple-calculation-type-switch").prop("checked", false);
    $(".calculation-type-selector-switch #detailed-calculation-type-switch").prop("checked", true);

    // Update session variable
    setLightingCalculationType("detailed").then(response => {
        checkTechnologyData();
    }).catch(error => {
        alert("Error saving lighting calculation type");
    });
}


