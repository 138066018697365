import {apiRequest} from "./index";
import {propertyLookupUpdateSteps} from "../property-energy-form";




/*
    * Load the property's current form step's html
    * @param {number} propertyId
 */
export function loadCurrentPropertyFormStep(propertyId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'loadCurrentPropertyFormStep', {
            propertyId: propertyId
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Change the property form step and load the new step's html
    * @param {number} propertyId
    * @param {string} stepName
 */
export function loadAndChangePropertyFormStep(propertyId, stepName){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'loadAndChangePropertyFormStep', {
            propertyId: propertyId,
            stepName: stepName
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}


/*
    * Clear property selection form
 */
export function clearPropertySelectionForm(){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'clearPropertySelectionForm', {

        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Create property energy lookup
    * @param {number} propertyId
 */
export function createPropertyEnergyLookup(propertyId, postcode, ownerName){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'createPropertyEnergyLookup', {
            propertyId: propertyId,
            postcode: postcode,
            ownerName: ownerName,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Look up property Gas address options
    * @param {number} energyLookupId
 */
export function propertyGasAddressLookup(energyLookupId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'propertyGasAddressLookup', {
            energyLookupId: energyLookupId,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Look up property Electricity address options
    * @param {number} energyLookupId
 */
export function propertyElectricityAddressLookup(energyLookupId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'propertyElectricityAddressLookup', {
            energyLookupId: energyLookupId,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Get property's energy lookup
    * @param {number} propertyId
 */
export function getPropertyEnergyLookup(propertyId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'getPropertyEnergyLookup', {
            propertyId: propertyId,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Set energy lookup Gas address
    * @param {number} energyLookupId
    * @param {number} addressId
 */
export function setEnergyLookupGasAddress(energyLookupId, addressId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'setEnergyLookupGasAddress', {
            energyLookupId: energyLookupId,
            gasAddressId: addressId,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Set energy lookup Electricity address
    * @param {number} energyLookupId
    * @param {number} addressId
 */
export function setEnergyLookupElectricityAddress(energyLookupId, addressId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'setEnergyLookupElectricityAddress', {
            energyLookupId: energyLookupId,
            electricityAddressId: addressId,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Get Gas meter lookup
    * @param {number} energyLookupId
 */
export function getGasMeterLookup(energyLookupId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'getGasMeterLookup', {
            energyLookupId: energyLookupId,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Get Electricity meter lookup
    * @param {number} energyLookupId
 */
export function getElectricityMeterLookup(energyLookupId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'getElectricityMeterLookup', {
            energyLookupId: energyLookupId,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Get EPC addresses from postcode
    * @param {string} postcode
 */
export function getEPCAddresses(postcode){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'getEPCAddresses', {
            postcode: postcode,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Submit the selected EPC address
    * @param {string} postcode
    * @param {string} address
    * @param {number} propertyId
 */
export function submitEPCAddress(postcode, address, propertyId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'submitEPCAddress', {
            propertyId: propertyId,
            address: address,
            postcode: postcode,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Create property and change step to its first step
    * @param {string} identifier - Property identifier
 */
export function createPropertyAndChangeStep(identifier){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'createPropertyAndChangeStep', {
            identifier: identifier,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Save property manual energy data
    * @param {id} propertyId - Property id
    * @param {string} electricityStartDate - Electricity period start date
    * @param {string} electricityEndDate - Electricity period end date
    * @param {number} electricityConsumption - Electricity consumption
    * @param {string} heatingFuelType - Heating fuel type
    * @param {string} heatingStartDate - Heating period start date
    * @param {string} heatingEndDate - Heating period end date
    * @param {number} heatingConsumption - Heating consumption
 */
export function savePropertyManualEnergyAndChangeStep(propertyId, electricityConsumption, heatingFuelType, heatingConsumption, assessmentStartDate, assessmentEndDate){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'savePropertyManualEnergyAndChangeStep', {
            propertyId: propertyId,
            electricityConsumption: electricityConsumption,
            heatingFuelType: heatingFuelType,
            heatingConsumption: heatingConsumption,
            assessmentStartDate: assessmentStartDate,
            assessmentEndDate: assessmentEndDate
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

export function savePropertyManualData(propertyId, addressLine1, addressLine2, addressCity, addressPostcode, buildingType, floorArea){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'savePropertyManualData', {
            propertyId: propertyId,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            addressCity: addressCity,
            addressPostcode: addressPostcode,
            buildingType: buildingType,
            floorArea: floorArea,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error){
            reject(error);
        })
    });
}


export function savePropertyManualConsumption(propertyId, fuelType, consumption){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'savePropertyManualConsumption', {
            propertyId: propertyId,
            fuelType: fuelType,
            consumption: consumption,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error){
            reject(error);
        })
    });
}

/*
    * Save property assumptions and refresh step html
    * @param {number} propertyId
    * @param {number} electricityFuelRate
    * @param {number} heatingFuelRate
    * @param {string} buildingType
    * @param {number} floorArea
 */
export function savePropertyAssumptionsAndRefreshStep(propertyId, electricityFuelRate, heatingFuelRate, buildingType, floorArea){
    return new Promise(function(resolve, reject){
       apiRequest('property', 'savePropertyAssumptionsAndRefreshStep', {
           propertyId: propertyId,
           electricityFuelRate: electricityFuelRate,
           heatingFuelRate: heatingFuelRate,
           buildingType: buildingType,
           floorArea: floorArea
       }).then(function(response) {
           if (response.success) {
               resolve(response);
           } else {
               reject(response);
           }
       }).catch(function(error){
           reject(error);
       })
    });
}

export function refreshPropertyEnergyLookup(propertyId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'refreshPropertyEnergyLookup', {
            propertyId: propertyId
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error){
            reject(error);
        })
    });
}

/*
    * Delete property
    * @param {number} propertyId
 */
export function deleteProperty(propertyId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'deleteProperty', {
            propertyId: propertyId
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error){
            reject(error);
        })
    });
}


export function skipEnergyLookupElectricity(energyLookupId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'skipEnergyLookupElectricityAddress', {
            energyLookupId: energyLookupId
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error){
            reject(error);
        })
    });
}

export function skipEnergyLookupGas(energyLookupId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'skipEnergyLookupGas', {
            energyLookupId: energyLookupId
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error){
            reject(error);
        })
    });
}

/*
    * Set meter for gas
    * @param {number} energyLookupId
    * @param {number} meterId
 */
export function setEnergyLookupGasMeter(energyLookupId, meterId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'setEnergyLookupGasMeter', {
            energyLookupId: energyLookupId,
            meterId: meterId,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}

/*
    * Set meter for electricity
    * @param {number} energyLookupId
    * @param {number} meterId
 */
export function setEnergyLookupElectricityMeter(energyLookupId, meterId){
    return new Promise(function(resolve, reject){
        apiRequest('property', 'setEnergyLookupElectricityMeter', {
            energyLookupId: energyLookupId,
            meterId: meterId,
        }).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}
