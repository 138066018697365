const Joi = require('joi');

let cJoi = Joi.extend(require('joi-phone-number'));

// Clipboard.js
import ClipboardJS from 'clipboard';

const body = $('body');


body.on('click', '.details-page #heating-consumption-calculator', function(e) {
    //Open modal
    $(".modal.heating-consumption-calculator-modal").addClass("open");
});

body.on('click', '.warning-modal-tooltip', function(e) {
    //Open modal
    $(".modal.fossil-fuel-warning-modal").addClass("open");
});

body.on('click', '.details-page #submitHeatingConsumptionCalculator', function(e){
    const buildingTypeId = $(".modal.heating-consumption-calculator-modal #building-type .button .selected").data("value");
    const mainHeatingFuelType = $(".modal.heating-consumption-calculator-modal #main-heating-fuel-type .button .selected").data("value");
    const totalFuelConsumptionElement = $(".modal.heating-consumption-calculator-modal #total-fuel-consumption");
    const totalFuelConsumption = totalFuelConsumptionElement.val();

    let errors = false;

    //Check inputs
    if(buildingTypeId === 0){
        $(".modal.heating-consumption-calculator-modal #building-type").closest(".input").addClass("error");
        errors = true;
    } else {
        $(".modal.heating-consumption-calculator-modal #building-type").closest(".input").removeClass("error");
    }

    if(totalFuelConsumption === ""){
        totalFuelConsumptionElement.closest(".input").addClass("error");
        errors = true;
    } else {
        totalFuelConsumptionElement.closest(".input").removeClass("error");
    }

    if(mainHeatingFuelType === 0){
        $(".modal.heating-consumption-calculator-modal #main-heating-fuel-type").closest(".input").addClass("error");
        errors = true;
    } else {
        $(".modal.heating-consumption-calculator-modal #main-heating-fuel-type").closest(".input").removeClass("error");
    }

    //If no errors, submit
    if(!errors){
        //Close modal
        $(".modal.heating-consumption-calculator-modal").removeClass("open");

        //Send AJAX Request
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: {
                data: JSON.stringify({
                    action: "heatingConsumptionCalculator",
                    buildingTypeId: buildingTypeId,
                    totalFuelConsumption: totalFuelConsumption,
                    mainHeatingFuelType: mainHeatingFuelType
                })
            },
            success: function (data) {
                //Update heating fuel consumption field
                $("#heatingConsumption").val(data.heating);

                //Update hot water fuel consumption field
                $("#hotWaterConsumption").val(data.hotWater);

                //Clear fields
                $(".modal.heating-consumption-calculator-modal #building-type .button .selected").data("value", 0).text("Select an option");
                $(".modal.heating-consumption-calculator-modal #main-heating-fuel-type .button .selected").data("value", 0).text("Select an option");
                totalFuelConsumptionElement.val("");
            }
        });
    }
});

body.on('click', '.details-page #heating-fuel-calculator', function(e) {
    //Open modal
    $(".modal.heating-fuel-calculator-modal").addClass("open");
});

body.on('input', '.details-page .modal .input.error input:not(.searchbar-input)', function(e) {
    let input = $(this).closest(".input");

    // Remove error class
    input.removeClass("error");
});

// Total up consumption figures and elsewhere figures and set the total energy consumption figure
body.on('input', '.consumption-fields #gasConsumption, #electricityConsumption, .consumption-fields #keroseneConsumption, .consumption-fields #lpgConsumption, .details-page .consumption-split-fields #heatingConsumption, .consumption-split-fields #hotWaterConsumption', function(e) {
    calculateTotalConsumption();
    calculateElsewhereConsumption();
    updateConsumptionSplit();
});


// Consumption fields block toggle
body.on('click', '.details-page #heatingFuelType .dropdown-content li', function(e) {
    // Container for all fuel consumption fields
    let consumptionFieldsBlock = $('.consumption-fields-block');

    // Emissions consumption fields
    let emissionsConsumptionFields = $('.emissions-panel');

    // Electricity fuel consumption field
    let electricityFuelConsumptionField = $('.electricity-fuel-consumption-field');

    // Gas fuel consumption field
    let gasFuelConsumptionField = $('.gas-fuel-consumption-field');

    // Kerosene fuel consumption field
    let keroseneFuelConsumptionField = $('.kerosene-fuel-consumption-field');

    // LPG fuel consumption field
    let lpgFuelConsumptionField = $('.lpg-fuel-consumption-field');

    // Electricity fuel emissions field
    let electricityFuelEmissionsField = $('.electricity-fuel-emissions-field');

    // Gas fuel emissions field
    let gasFuelEmissionsField = $('.gas-fuel-emissions-field');

    // Kerosene fuel emissions field
    let keroseneFuelEmissionsField = $('.kerosene-fuel-emissions-field');

    // LPG fuel emissions field
    let lpgFuelEmissionsField = $('.lpg-fuel-emissions-field');

    // Container for all fossil fuel rates fields
    let fossilFuelRates = $('.fossil-fuel-rates');

    // Gas fuel rate field
    let gasFuelRateField = $('.gas-fuel-rate-field');

    // Kerosene fuel rate field
    let keroseneFuelRateField = $('.kerosene-fuel-rate-field');

    // LPG fuel rate field
    let lpgFuelRateField = $('.lpg-fuel-rate-field');

    // Kerosene ad LPG help text
    let keroseneLPGHelp = $('.kerosene-lpg-help');

    // Get selected value
    let selectedValue = $(this).text();

    // Check to see if required groups variable is set
    if(typeof requiredFormGroups !== 'undefined'){
        // Switch selected value
        switch(selectedValue){
            case "Select an option":
                let hasConsumptionFields = false;

                // Only hide gas fuel fields if not required
                if(!requiredFormGroups.includes("gas_consumption")){
                    // Hide gas fuel consumption field
                    gasFuelConsumptionField.hide();

                    // Show gas fuel emissions field
                    gasFuelEmissionsField.show();
                } else {
                    hasConsumptionFields = true;
                }

                // Only hide gas rate if not required
                if(!requiredFormGroups.includes("gas_rate")){
                    // Hide gas fuel rate field
                    gasFuelRateField.hide();
                }


                // Only hide electricity fuel fields if not required
                if(!requiredFormGroups.includes("electricity_consumption")){
                    // Hide electricity fuel consumption field
                    electricityFuelConsumptionField.hide();

                    // Show electricity fuel emissions field
                    electricityFuelEmissionsField.show();
                } else {
                    hasConsumptionFields = true;
                }

                // Show emissions consumption fields
                emissionsConsumptionFields.slideDown();

                // Hide kerosene fuel consumption field
                keroseneFuelConsumptionField.hide();

                // Hide kerosene fuel rate field
                keroseneFuelRateField.hide();

                // Show kerosene fuel emissions field
                keroseneFuelEmissionsField.show();

                // Hide LPG fuel consumption field
                lpgFuelConsumptionField.hide();

                // Hide LPG fuel rate field
                lpgFuelRateField.hide();

                // Show LPG fuel emissions field
                lpgFuelEmissionsField.show();

                // Hide Kerosene and LPG help text
                keroseneLPGHelp.hide();

                if(hasConsumptionFields){
                    // Show consumption fields block
                    consumptionFieldsBlock.slideDown();
                } else {
                    // Hide consumption fields block
                    consumptionFieldsBlock.slideUp();

                    // Hide fossil fuel rates
                    fossilFuelRates.slideUp();
                }
                break;
            case "Electricity":
                // Show emissions consumption fields
                emissionsConsumptionFields.slideDown();

                // Show consumption fields block
                consumptionFieldsBlock.slideDown();

                // Hide kerosene fuel consumption field
                keroseneFuelConsumptionField.hide();

                // Hide kerosene fuel rate field
                keroseneFuelRateField.hide();

                // Show kerosene fuel emissions field
                keroseneFuelEmissionsField.show();

                // Hide LPG fuel consumption field
                lpgFuelConsumptionField.hide();

                // Hide LPG fuel rate field
                lpgFuelRateField.hide();

                // Show LPG fuel emissions field
                lpgFuelEmissionsField.show();

                // Only hide gas if not required
                if(!requiredFormGroups.includes("gas_consumption")){
                    // Hide gas fuel consumption field
                    gasFuelConsumptionField.hide();

                    // Show gas fuel emissions field
                    gasFuelEmissionsField.show();
                }

                // Only hide gas rate if not required
                if(!requiredFormGroups.includes("gas_rate")){
                    // Hide gas fuel rate field
                    gasFuelRateField.hide();
                }

                // Show electric fuel consumption field
                electricityFuelConsumptionField.show();

                // Hide electric fuel emissions field
                electricityFuelEmissionsField.hide();

                // Hide Kerosene and LPG help text
                keroseneLPGHelp.hide();
                break;
            case "Gas":
                // Show emissions consumption fields
                emissionsConsumptionFields.slideDown();

                // Show consumption fields block
                consumptionFieldsBlock.slideDown();

                // Show gas fuel consumption field
                gasFuelConsumptionField.show();

                // Hide gas fuel emissions field
                gasFuelEmissionsField.hide();

                // Show fossil fuel rates
                fossilFuelRates.slideDown();

                // Show gas fuel rate field
                gasFuelRateField.show();

                // Hide kerosene fuel consumption field
                keroseneFuelConsumptionField.hide();

                // Hide kerosene fuel rate field
                keroseneFuelRateField.hide();

                // Show kerosene fuel emissions field
                keroseneFuelEmissionsField.show();

                // Hide LPG fuel consumption field
                lpgFuelConsumptionField.hide();

                // Hide LPG fuel rate field
                lpgFuelRateField.hide();

                // Show LPG fuel emissions field
                lpgFuelEmissionsField.show();

                // Hide Kerosene and LPG help text
                keroseneLPGHelp.hide();

                // Only hide electric if not required
                if(!requiredFormGroups.includes("electricity_consumption")){
                    // Hide electric fuel consumption field
                    electricityFuelConsumptionField.hide();

                    // Show electric fuel emissions field
                    electricityFuelEmissionsField.show();
                } else {
                    // Hide electric fuel emissions field
                    electricityFuelEmissionsField.hide();
                }
                break;
            case "Kerosene":
                // Show emissions consumption fields
                emissionsConsumptionFields.slideDown();

                // Show consumption fields block
                consumptionFieldsBlock.slideDown();

                // Hide LPG fuel consumption field
                lpgFuelConsumptionField.hide();

                // Hide LPG fuel rate field
                lpgFuelRateField.hide();

                // Show LPG fuel emissions field
                lpgFuelEmissionsField.show();

                // Show fossil fuel rates
                fossilFuelRates.slideDown();

                // Show kerosene fuel consumption field
                keroseneFuelConsumptionField.show();

                // Show kerosene fuel rate field
                keroseneFuelRateField.show();

                // Hide kerosene fuel emissions field
                keroseneFuelEmissionsField.hide();

                // Show Kerosene and LPG help text
                keroseneLPGHelp.show();

                // Only hide gas if not required
                if(!requiredFormGroups.includes("gas_consumption")){
                    // Hide gas fuel consumption field
                    gasFuelConsumptionField.hide();

                    // Show gas fuel emissions field
                    gasFuelEmissionsField.show();
                }

                // Only hide gas rate if not required
                if(!requiredFormGroups.includes("gas_rate")){
                    // Hide gas fuel rate field
                    gasFuelRateField.hide();
                }

                // Only hide electric if not required
                if(!requiredFormGroups.includes("electricity_consumption")){
                    // Hide electric fuel consumption field
                    electricityFuelConsumptionField.hide();

                    // Show electric fuel emissions field
                    electricityFuelEmissionsField.show();
                }
                break;
            case "LPG":
                // Show emissions consumption fields
                emissionsConsumptionFields.slideDown();

                // Show consumption fields block
                consumptionFieldsBlock.slideDown();

                // Show fossil fuel rates
                fossilFuelRates.slideDown();

                // Show LPG fuel consumption field
                lpgFuelConsumptionField.show();

                // Show LPG fuel rate field
                lpgFuelRateField.show();

                // Hide LPG fuel emissions field
                lpgFuelEmissionsField.hide();

                // Hide kerosene fuel consumption field
                keroseneFuelConsumptionField.hide();

                // Hide kerosene fuel rate field
                keroseneFuelRateField.hide();

                // Show kerosene fuel emissions field
                keroseneFuelEmissionsField.show();

                // Show Kerosene and LPG help text
                keroseneLPGHelp.show();

                // Only hide gas if not required
                if(!requiredFormGroups.includes("gas_consumption")){
                    // Hide gas fuel consumption field
                    gasFuelConsumptionField.hide();

                    // Show gas fuel emissions field
                    gasFuelEmissionsField.show();
                }

                // Only hide gas rate if not required
                if(!requiredFormGroups.includes("gas_rate")){
                    // Hide gas fuel rate field
                    gasFuelRateField.hide();
                }

                // Only hide electric if not required
                if(!requiredFormGroups.includes("electricity_consumption")){
                    // Hide electric fuel consumption field
                    electricityFuelConsumptionField.hide();

                    // Show electric fuel emissions field
                    electricityFuelEmissionsField.show();
                }

                break;
            case "Mix":
                // Hide emissions consumption fields
                emissionsConsumptionFields.slideUp();

                // Show consumption fields block
                consumptionFieldsBlock.slideDown();

                // Show fossil fuel rates
                fossilFuelRates.slideDown();

                // Show kerosene fuel consumption field
                keroseneFuelConsumptionField.show();

                // Show kerosene fuel rate field
                keroseneFuelRateField.show();

                // Hide kerosene fuel emissions field
                keroseneFuelEmissionsField.hide();

                // Show LPG fuel consumption field
                lpgFuelConsumptionField.show();

                // Show LPG fuel rate field
                lpgFuelRateField.show();

                // Hide LPG fuel emissions field
                lpgFuelEmissionsField.hide();

                // Show gas fuel consumption field
                gasFuelConsumptionField.show();

                // Show gas fuel rate field
                gasFuelRateField.show();

                // Hide gas fuel emissions field
                gasFuelEmissionsField.hide();

                // Show electric fuel consumption field
                electricityFuelConsumptionField.show();

                // Hide electric fuel emissions field
                electricityFuelEmissionsField.hide();

                // Show Kerosene and LPG help text
                keroseneLPGHelp.show();
                break;
        }
    }
});

// Heating fuel calculator input change
body.on('input', '#convert-heating-fuel-rate', function(e){
    convertHeatingFuelRate($("#convert-heating-fuel-type .button .selected").data("value"), $(this).val());
});

// Heating fuel calculator type change
body.on('click', '#convert-heating-fuel-type .dropdown-content li', function(e) {
    convertHeatingFuelRate($(this).data("value"), $("#convert-heating-fuel-rate").val());
});

// Heating fuel calculator clipboard
new ClipboardJS('#heating-fuel-calculator-copy');

// Handle change in inputs with errors
body.on('input', '.details-page .input.error input:not(.searchbar-input)', function(e) {
   // Remove error class
    $(this).closest(".input").removeClass("error");

    // Remove error message
    $(this).closest(".input").find(".input-error, .page-block-error").remove();
});

// Handle change in dropdowns with errors
body.on('click', '.details-page .input.error .wtp-dropdown .dropdown-content li, .details-page .input.error .dropdown .dropdown-content li', function(e) {
   // Remove error class
    $(this).closest(".input").removeClass("error");

    // Remove error message
    $(this).closest(".input").find(".input-error").remove();
});

body.on('click', '.details-page #includePropertyOwner', function(e) {
    if($("#includePropertyOwner").is(":checked")){
        $(".block#ownerEmailBlock").slideDown("fast");
    } else {
        $(".block#ownerEmailBlock").slideUp("fast");
        $(".input input#ownerEmail").val("");
    }
});

body.on('click', '.details-page #degreeDaysToggle', function(e) {
    if($("#degreeDaysToggle").is(":checked")){
        $(".degree-day-fields").slideDown("fast");
    } else {
        $(".degree-day-fields").slideUp("fast");
    }
});

body.on('change', '#useManualDegreeDays', function(e) {
    let degreeDaysManual = $('.manual-degree-days-fields');

    if($(this).is(":checked")){
        degreeDaysManual.slideDown("fast");
    } else {
        degreeDaysManual.slideUp("fast");
    }
});

body.on('change', '#toggleEmissionsConsumptionFields', function(e) {
    if($(this).is(":checked")){
        //Show fields
        $("#emissionsConsumptionFields").slideDown("fast");
    } else {
        //Hide fields
        $("#emissionsConsumptionFields").slideUp("fast");
    }
});

body.on('change', '#tcConfirm', function(e) {
    if($(this).is(":checked")) {
        $(".actions-bar-container .buttons-row .next").removeAttr("disabled");
    } else {
        $(".actions-bar-container .buttons-row .next").attr("disabled", true);
    }
});


body.on('change', '.details-page #electricityCountryAverage', function(e) {
    let checkBox = $(this);
    let dayRate = $('#dayRate');
    let nightRate = $('#nightRate');

    if($(this).is(":checked")) {
        dayRate.prop("disabled", true);
        nightRate.prop("disabled", true);
        checkBox.prop("disabled", true);

        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "countryAverageElectricity",
                    country: $(".wtp-dropdown#country .button .selected").text()
            })},
            success: function(data){
                // Enable checkbox
                checkBox.prop("disabled", false);

                // Set day and night rate
                dayRate.val(data);
                nightRate.val(data);

                // Remove any error classes
                dayRate.closest(".input").removeClass("error");
                nightRate.closest(".input").removeClass("error");

                // Remove any error messages
                dayRate.closest(".input").find(".input-error").remove();
                nightRate.closest(".input").find(".input-error").remove();
            }
        });
    } else {
        dayRate.removeAttr("disabled");
        nightRate.removeAttr("disabled");
    }
});

// Consumption split method toggle
body.on('change', '.details-page #consumptionSplitMethodToggle', function(e) {
    if($(this).is(":checked")){
        // Enable heating and hot water consumption fields
        $("#heatingConsumption, #hotWaterConsumption").prop("disabled", false);
    } else {
        // Disable heating and hot water consumption fields
        $("#heatingConsumption, #hotWaterConsumption").prop("disabled", true);

        // Use factors to calculate consumption splits
        updateConsumptionSplit();
    }
});

// EPC building type
body.on('click', '.details-page .input .wtp-dropdown#epcBuildingType .dropdown-content li', function(e) {
    // Get selected value
    let selectedValue = $(this).data('value');

    // Heating fuel type value
    let heatingFuelTypeValue = $(".wtp-dropdown#heatingFuelType .button .selected").text();

    // Main heating fuel type
    let mainHeatingFuelType = "";

    // Total fuel consumption
    let totalFuelConsumption = 0;

    switch(heatingFuelTypeValue){
        case "Electricity":
            mainHeatingFuelType = "electricity";
            totalFuelConsumption = $("#electricityConsumption").val();
            break;
        case "Gas":
            mainHeatingFuelType = "gas";
            totalFuelConsumption = $("#gasConsumption").val();
            break;
        case "Kerosene":
            mainHeatingFuelType = "gas";
            totalFuelConsumption = $("#keroseneConsumption").val();
            break;
        case "LPG":
            mainHeatingFuelType = "gas";
            totalFuelConsumption = $("#lpgConsumption").val();
            break;
        case "Mix":
            mainHeatingFuelType = "gas";
            totalFuelConsumption = $("#electricityConsumption").val() + $("#gasConsumption").val() + $("#keroseneConsumption").val() + $("#lpgConsumption").val();
            break;
    }

    // Send AJAX Request
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "consumptionSplitFactor",
                buildingTypeId: selectedValue,
            })
        },
        success: function (data) {
            if(typeof data.error === 'undefined' || data.error === false){
                // Set consumption split factors
                $("#consumptionSplitFactorFossilHeating").val(data.fossilHeating);
                $("#consumptionSplitFactorFossilHotWater").val(data.fossilHotWater);
                $("#consumptionSplitFactorFossilCatering").val(data.fossilCatering);
                $("#consumptionSplitFactorElectricHeating").val(data.electricHeating);
                $("#consumptionSplitFactorElectricHotWater").val(data.electricHotWater);
                $("#consumptionSplitFactorElectricCatering").val(data.electricCatering);

                // Update consumption split
                updateConsumptionSplit();
            } else {
                // Set consumption split factors
                $("#consumptionSplitFactorFossilHeating").val(0);
                $("#consumptionSplitFactorFossilHotWater").val(0);
                $("#consumptionSplitFactorFossilCatering").val(0);
                $("#consumptionSplitFactorElectricHeating").val(0);
                $("#consumptionSplitFactorElectricHotWater").val(0);
                $("#consumptionSplitFactorElectricCatering").val(0);

                // Update consumption split
                updateConsumptionSplit();
            }
        }
    });
});

export default async function saveDetailsPage() {
    // Remove page errors
    $(".page-block-error").remove();

    // Check to see if required groups variable is set
    if(typeof requiredFormGroups === 'undefined'){
        alert("Required form groups not set, refresh the page and try again.");
        return;
    }

    let localRequiredFormGroups = requiredFormGroups;

    // Base scheme array
    let scheme = {
        // General details
        emailAddress: cJoi.string().email({tlds: {allow: false}}).min(6).max(254).required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.email': 'Please enter a valid email address',
            'string.empty': 'Please enter an email address',
            'any.required': 'Please enter an email address'
        }),
        firstName: cJoi.string().min(0).max(50).alphanum().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a first name',
            'any.required': 'Please enter a first name'
        }),
        lastName: cJoi.string().min(0).max(50).alphanum().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a last name',
            'any.required': 'Please enter a last name'
        }),
        company: cJoi.string().min(0).max(100).messages({
            'string.max': 'Please enter at most {#limit} characters',
        }),
        phoneNumber: cJoi.string().min(0).max(20).messages({
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a phone number',
            'any.required': 'Please enter a phone number',
            'phoneNumber.invalid': 'Please enter a valid phone number'
        }),
        includePropertyOwner: cJoi.bool(),
        ownerEmailAddress: cJoi.when('includePropertyOwner', {is: true, then: cJoi.string().required().email({tlds: {allow: false}}).min(6).max(254)}).messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.email': 'Please enter a valid email address',
            'string.empty': 'Please enter an email address',
            'any.required': 'Please enter an email address'
        }),

        // Property location
        addressLine1: cJoi.string().min(1).max(100).required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter the first line of the address',
            'any.required': 'Please enter the first line of the address'
        }),
        addressLine2: cJoi.string().min(0).max(100).messages({
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter the first line of the address',
        }),
        city: cJoi.string().min(1).max(50).required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a city',
            'any.required': 'Please enter a city'
        }),
        postalCode: cJoi.string().min(1).max(10).required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a postal/zip code',
            'any.required': 'Please enter a postal/zip code'
        }),
        country: cJoi.string().min(1).max(50).required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please select a country',
            'any.required': 'Please select a country'
        }),

        // Rates
        dayRate: cJoi.number().min(0).required().messages({
            'number.min': 'Please enter a day rate',
            'number.max': 'Please enter a day rate',
            'number.empty': 'Please enter a day rate',
            'any.required': 'Please enter a day rate',
            'number.base': 'Please enter a day rate'
        }),
    };

    // Base field values array
    let fieldValues = {
        // General details
        emailAddress: $("#emailAddress").val(),
        firstName: $("#firstName").val(),
        lastName: $("#lastName").val(),
        company: $("#company").val(),
        phoneNumber: $("#phoneNumber").val(),
        includePropertyOwner: $("#includePropertyOwner").is(":checked"),
        ownerEmailAddress: $("#ownerEmailAddress").val(),

        // Property location
        addressLine1: $("#addressLine1").val(),
        addressLine2: $("#addressLine2").val(),
        city: $("#city").val(),
        postalCode: $("#postalCode").val(),
        country: $("#country .button .selected").text(),

        // Rates
        dayRate: $("#dayRate").val(),
    }

    // Add conditional field groups
    if(localRequiredFormGroups.includes("heating_fuel_type")){
        switch($(".wtp-dropdown#heatingFuelType .button .selected").text()) {
            case "Electricity":
                // Add electricity consumption
                if(!localRequiredFormGroups.includes("electricity_consumption")){
                    localRequiredFormGroups.push("electricity_consumption");
                }
                break;
            case "Mix":
                // Add gas consumption
                if(!localRequiredFormGroups.includes("gas_consumption")){
                    localRequiredFormGroups.push("gas_consumption");
                }

                // Add gas rate
                if(!localRequiredFormGroups.includes("gas_rate")){
                    localRequiredFormGroups.push("gas_rate");
                }

                // Add electricity consumption
                if(!localRequiredFormGroups.includes("electricity_consumption")){
                    localRequiredFormGroups.push("electricity_consumption");
                }

                // Add kerosene consumption
                if(!localRequiredFormGroups.includes("kerosene_consumption")){
                    localRequiredFormGroups.push("kerosene_consumption");
                }

                // Add kerosene rate
                if(!localRequiredFormGroups.includes("kerosene_rate")){
                    localRequiredFormGroups.push("kerosene_rate");
                }

                // Add lpg consumption
                if(!localRequiredFormGroups.includes("lpg_consumption")){
                    localRequiredFormGroups.push("lpg_consumption");
                }

                // Add lpg rate
                if(!localRequiredFormGroups.includes("lpg_rate")){
                    localRequiredFormGroups.push("lpg_rate");
                }
                break;
            case "Kerosene":
                // Add kerosene consumption
                if(!localRequiredFormGroups.includes("kerosene_consumption")){
                    localRequiredFormGroups.push("kerosene_consumption");
                }

                // Add kerosene rate
                if(!localRequiredFormGroups.includes("kerosene_rate")){
                    localRequiredFormGroups.push("kerosene_rate");
                }
                break;
            case "LPG":
                // Add lpg consumption
                if(!localRequiredFormGroups.includes("lpg_consumption")){
                    localRequiredFormGroups.push("lpg_consumption");
                }

                // Add lpg rate
                if(!localRequiredFormGroups.includes("lpg_rate")){
                    localRequiredFormGroups.push("lpg_rate");
                }
                break;
        }
    }

    // Loop required form groups
    localRequiredFormGroups.forEach(function(group){
        // Add additional fields if required
       switch(group.toLowerCase()) {
           case "floor_area":
                // Add to scheme
                scheme.floorArea = cJoi.number().min(1).required().messages({
                    'number.min': 'Please enter a floor area',
                    'number.max': 'Please enter a floor area',
                    'number.empty': 'Please enter a floor area',
                    'any.required': 'Please enter a floor area',
                    'number.base': 'Please enter a floor area'
                })

                // Add to field values
                fieldValues.floorArea = $("#floorArea").val();
                break;
           case "assessment_period":
                // Add to scheme
                scheme.assessmentStartDate = cJoi.date().required().messages({
                     'date.base': 'Please enter a valid date',
                     'date.empty': 'Please enter a valid date',
                     'any.required': 'Please enter a valid date'
                });
                scheme.assessmentEndDate = cJoi.date().required().messages({
                     'date.base': 'Please enter a valid date',
                     'date.empty': 'Please enter a valid date',
                     'any.required': 'Please enter a valid date'
                });

                // Add to field values
                fieldValues.assessmentStartDate = $("#assessmentStartDate").val();
                fieldValues.assessmentEndDate = $("#assessmentEndDate").val();
                break;
           case "heating_fuel_type":
                // Add to scheme
                scheme.heatingFuelType = cJoi.string().min(1).max(128).invalid('Select an option').required().messages({
                    'number.min': 'Please select a heating fuel type',
                    'number.max': 'Please select a heating fuel type',
                    'number.empty': 'Please select a heating fuel type',
                    'any.required': 'Please select a heating fuel type',
                    'any.invalid': 'Please select a heating fuel type'
                });

                // Add to field values
                fieldValues.heatingFuelType = $(".wtp-dropdown#heatingFuelType .button .selected").text();
               break;
           case "electricity_consumption":
                // Add to scheme
                scheme.electricityConsumption = cJoi.number().min(0).required().messages({
                    'number.min': 'Please enter an electricity consumption',
                    'number.max': 'Please enter an electricity consumption',
                    'number.empty': 'Please enter an electricity consumption',
                    'any.required': 'Please enter an electricity consumption',
                    'number.base': 'Please enter an electricity consumption'
                });

                // Add to field values
                fieldValues.electricityConsumption = $("#electricityConsumption").val();
               break;
           case "gas_consumption":
                // Add consumption to field scheme
                scheme.gasConsumption = cJoi.number().min(0).required().messages({
                    'number.min': 'Please enter a gas consumption',
                    'number.max': 'Please enter a gas consumption',
                    'number.empty': 'Please enter a gas consumption',
                    'any.required': 'Please enter a gas consumption',
                    'number.base': 'Please enter a gas consumption'
                });

                // Add to consumption to values
                fieldValues.gasConsumption = $("#gasConsumption").val();
               break;
           case "kerosene_consumption":
                // Add to scheme
                scheme.keroseneConsumption = cJoi.number().min(0).required().messages({
                    'number.min': 'Please enter a kerosene consumption',
                    'number.max': 'Please enter a kerosene consumption',
                    'number.empty': 'Please enter a kerosene consumption',
                    'any.required': 'Please enter a kerosene consumption',
                    'number.base': 'Please enter a kerosene consumption'
                });

                // Add to field values
                fieldValues.keroseneConsumption = $("#keroseneConsumption").val();
               break;
           case "lpg_consumption":
                // Add to scheme
                scheme.lpgConsumption = cJoi.number().min(0).required().messages({
                    'number.min': 'Please enter a LPG consumption',
                    'number.max': 'Please enter a LPG consumption',
                    'number.empty': 'Please enter a LPG consumption',
                    'any.required': 'Please enter a LPG consumption',
                    'number.base': 'Please enter a LPG consumption'
                });

                // Add to field values
                fieldValues.lpgConsumption = $("#lpgConsumption").val();
               break;
           case "consumption_split":
                // Add to scheme
                scheme.heatingConsumption = cJoi.number().min(0).required().messages({
                    'number.min': 'Please enter a heating consumption',
                    'number.max': 'Please enter a heating consumption',
                    'number.empty': 'Please enter a heating consumption',
                    'any.required': 'Please enter a heating consumption',
                    'number.base': 'Please enter a heating consumption'
                });
                scheme.hotWaterConsumption = cJoi.number().min(0).required().messages({
                    'number.min': 'Please enter a hot water consumption',
                    'number.max': 'Please enter a hot water consumption',
                    'number.empty': 'Please enter a hot water consumption',
                    'any.required': 'Please enter a hot water consumption',
                    'number.base': 'Please enter a hot water consumption'
                });

                // Add to field values
                fieldValues.heatingConsumption = $("#heatingConsumption").val();
                fieldValues.hotWaterConsumption = $("#hotWaterConsumption").val();
                break;
           case "gas_rate":
                // Add to scheme
                scheme.gasRate = cJoi.number().min(0).required().messages({
                    'number.min': 'Please enter a gas rate',
                    'number.max': 'Please enter a gas rate',
                    'number.empty': 'Please enter a gas rate',
                    'any.required': 'Please enter a gas rate',
                    'number.base': 'Please enter a gas rate'
                });

                // Add to field values
                fieldValues.gasRate = $("#gasRate").val();
               break;
           case "kerosene_rate":
               // Add kerosene rate to field scheme
               scheme.keroseneRate = cJoi.number().min(0).required().messages({
                   'number.min': 'Please enter a kerosene rate',
                   'number.max': 'Please enter a kerosene rate',
                   'number.empty': 'Please enter a kerosene rate',
                   'any.required': 'Please enter a kerosene rate',
                   'number.base': 'Please enter a kerosene rate'
               });

               // Add gas rate to vales
               fieldValues.keroseneRate = $("#keroseneRate").val();
               break;
           case "lpg_rate":
               // Add lpg rate to field scheme
               scheme.lpgRate = cJoi.number().min(0).required().messages({
                   'number.min': 'Please enter a lpg rate',
                   'number.max': 'Please enter a lpg rate',
                   'number.empty': 'Please enter a lpg rate',
                   'any.required': 'Please enter a lpg rate',
                   'number.base': 'Please enter a lpg rate'
               });

               // Add gas rate to vales
               fieldValues.lpgRate = $("#lpgRate").val();
               break;
           case "degree_days":
               // If degree days are toggled and manual degree days are being used
                if($("#degreeDaysToggle").is(":checked") && $("#useManualDegreeDays").is(":checked")){
                    // Add to scheme
                    scheme.degreeDaysManual = cJoi.number().min(0).required().messages({
                        'number.min': 'Please enter degree days',
                        'number.max': 'Please enter degree days',
                        'number.empty': 'Please enter degree days',
                        'any.required': 'Please enter degree days',
                        'number.base': 'Please enter degree days'
                    });

                    scheme.standardDegreeDaysManual = cJoi.number().min(0).required().messages({
                        'number.min': 'Please enter standard degree days',
                        'number.max': 'Please enter standard degree days',
                        'number.empty': 'Please enter standard degree days',
                        'any.required': 'Please enter standard degree days',
                        'number.base': 'Please enter standard degree days'
                    });

                    // Add to field values
                    fieldValues.degreeDaysManual = $("#degreeDaysManual").val();
                    fieldValues.standardDegreeDaysManual = $("#standardDegreeDaysManual").val();
                }
               break;
       }
    });

    // Emissions panel
    if($("#toggleEmissionsConsumptionFields").is(":checked") && (!localRequiredFormGroups.includes("gas_consumption") || !localRequiredFormGroups.includes("electricity_consumption") || !localRequiredFormGroups.includes("kerosene_consumption") || !localRequiredFormGroups.includes("lpg_consumption"))){
        // Gas emissions consumption
        if(!localRequiredFormGroups.includes("gas_consumption")){
            // Add to scheme
            scheme.emissionsGasConsumption = cJoi.number().min(0).required().messages({
                'number.min': 'Please enter an gas consumption',
                'number.max': 'Please enter an gas consumption',
                'number.empty': 'Please enter an gas consumption',
                'any.required': 'Please enter an gas consumption',
                'number.base': 'Please enter an gas consumption'
            });

            // Add to field values
            fieldValues.emissionsGasConsumption = $("#emissionsGasConsumption").val();
        }

        // Electricity emissions consumption
        if(!localRequiredFormGroups.includes("electricity_consumption")){
            // Add to scheme
            scheme.emissionsElectricityConsumption = cJoi.number().min(0).required().messages({
                'number.min': 'Please enter an electricity consumption',
                'number.max': 'Please enter an electricity consumption',
                'number.empty': 'Please enter an electricity consumption',
                'any.required': 'Please enter an electricity consumption',
                'number.base': 'Please enter an electricity consumption'
            });

            // Add to field values
            fieldValues.emissionsElectricityConsumption = $("#emissionsElectricityConsumption").val();
        }

        // Kerosene emissions consumption
        if(!localRequiredFormGroups.includes("kerosene_consumption")){
            // Add to scheme
            scheme.emissionsKeroseneConsumption = cJoi.number().min(0).required().messages({
                'number.min': 'Please enter an kerosene consumption',
                'number.max': 'Please enter an kerosene consumption',
                'number.empty': 'Please enter an kerosene consumption',
                'any.required': 'Please enter an kerosene consumption',
                'number.base': 'Please enter an kerosene consumption'
            });

            // Add to field values
            fieldValues.emissionsKeroseneConsumption = $("#emissionsKeroseneConsumption").val();
        }

        // LPG emissions consumption
        if(!localRequiredFormGroups.includes("lpg_consumption")){
            // Add to scheme
            scheme.emissionsLPGConsumption = cJoi.number().min(0).required().messages({
                'number.min': 'Please enter an LPG consumption',
                'number.max': 'Please enter an LPG consumption',
                'number.empty': 'Please enter an LPG consumption',
                'any.required': 'Please enter an LPG consumption',
                'number.base': 'Please enter an LPG consumption'
            });

            // Add to field values
            fieldValues.emissionsLPGConsumption = $("#emissionsLPGConsumption").val();
        }
    }

    // Create schema
    const schema = cJoi.object(scheme);

    // Validate inputs
    const { error } = schema.validate(fieldValues,{
        abortEarly: false,
    });

    // Remove existing error classes
    $(".input.error").removeClass("error");

    // Remove existing error messages
    $(".input-error").remove();

    // List of fields with errors
    let fieldsWithErrors = [];

    // Handle errors
    if(typeof error !== "undefined") {
        error.details.forEach(err => {
            // if already in list, skip
            if(fieldsWithErrors.indexOf(err.context.key) !== -1) return;

            // Get input name
            const name = err.context.key;

            // Add to fields with errors
            fieldsWithErrors.push(name);

            // Get error message
            const message = err.message;

            // Add error to input
            addErrorToInput(`#${name}`, message);

            console.log(err);
        });

        // Scroll up to first error
        scrollToError();

        return false;
    }

    // Conditional Degree Days validation
    if($("#degreeDaysToggle").is(":checked") && !$("#useManualDegreeDays").is(":checked")){
        console.log("Checking assessment period...");

        // Check assessment period
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: {
                data: JSON.stringify({
                    action: "checkAssessmentPeriod",
                    period: {
                        postcode: $("#postalCode").val(),
                        startDate: $("#assessmentStartDate").val(),
                        endDate: $("#assessmentEndDate").val(),
                    }
                })
            },
            success: function (data) {
                if(data.status === "error"){
                    switch(data.type){
                        case "bad_postcode":
                        case "invalid_postcode":
                            addErrorToAfterElement(`#degreeDaysToggle`, "No Degrees Days data could be found for the given postcode. Make sure the postcode is correct, if so, manually enter the data.", ".block");
                            break;
                        case "missing_end_date":
                        case "missing_start_date":
                        case "start_date_after_end_date":
                            addErrorToAfterElement(`#degreeDaysToggle`, "There was an issue with the assessment period dates. Make sure they are correct.", ".block");
                            break;
                    }

                    // Scroll up to first error
                    scrollToError();

                    return false;
                } else {
                    // Success
                    sendDetailsForm();
                }
            }
        });
    } else {
        sendDetailsForm();
    }
}

function sendDetailsForm() {
    console.log("Sending details form...");
    const detailsData = {
        // General details
        firstName: $("#firstName").val(),
        lastName: $("#lastName").val(),
        company: $("#company").val(),
        emailAddress: $("#emailAddress").val(),
        phoneNumber: $("#phoneNumber").val(),
        includePropertyOwner: $("#includePropertyOwner").is(":checked"),
        ownerEmailAddress: $("#ownerEmailAddress").val(),

        // Property
        addressLine1: $("#addressLine1").val(),
        addressLine2: $("#addressLine2").val(),
        city: $("#city").val(),
        postalCode: $("#postalCode").val(),
        country: $(".wtp-dropdown#country .button .selected").text(),
        floorArea: $("#floorArea").val(),

        // Usage
        assessmentStartDate: $("#assessmentStartDate").val(),
        assessmentEndDate: $("#assessmentEndDate").val(),
        heatingFuelType: $(".wtp-dropdown#heatingFuelType .button .selected").text(),
        epcBuildingType: $("#epcBuildingType .button .selected").data('value'),
        consumptionSplitMethodToggle: $("#consumptionSplitMethodToggle").is(":checked"),
        electricityConsumption: $("#electricityConsumption").val(),
        gasConsumption: $("#gasConsumption").val(),
        keroseneConsumption: $("#keroseneConsumption").val(),
        lpgConsumption: $("#lpgConsumption").val(),
        heatingConsumption: $("#heatingConsumption").val(),
        hotWaterConsumption: $("#hotWaterConsumption").val(),

        // Rates
        dayRate: $("#dayRate").val(),
        nightRate: $("#nightRate").val(),
        gasRate: $("#gasRate").val(),
        keroseneRate: $("#keroseneRate").val(),
        lpgRate: $("#lpgRate").val(),
        currency: $(".wtp-dropdown#currency .button .selected").text(),

        // Degree days
        degreeDaysToggled: $("#degreeDaysToggle").is(":checked"),
        degreeDaysUseManual: $("#useManualDegreeDays").is(":checked"),
        manualDegreeDays: $("#degreeDaysManual").val(),
        manualStandardDegreeDays: $("#standardDegreeDaysManual").val(),

        // Emissions
        emissionsConsumptionToggled: $("#toggleEmissionsConsumptionFields").is(":checked"),
        emissionsGasConsumption: $("#emissionsGasConsumption").val(),
        emissionsElectricityConsumption: $("#emissionsElectricityConsumption").val(),
        emissionsKeroseneConsumption: $("#emissionsKeroseneConsumption").val(),
        emissionsLPGConsumption: $("#emissionsLPGConsumption").val(),

        // Consent
        brokeringConsent: $("#brokeringConsent").is(":checked"),
        powerPurchaseAgreementConsent: $("#powerPurchaseAgreementConsent").is(":checked"),
    }

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "saveDetails",
                details: detailsData
            })},
        success: function (data) {
            console.log(data);
            if(typeof data.status !== "undefined" && data.status === "success"){
                // Redirect to overview page
                window.location.href = "overview";
            } else {
                console.log(data);
            }
        }
    });
}

function convertHeatingFuelRate(typeFactor, pricePerLitre) {
    let convertedValue = 0;

    if(!isNaN(pricePerLitre) && pricePerLitre !== "" && pricePerLitre !== 0){
        // Convert price per litre to float
        pricePerLitre = parseFloat(pricePerLitre);

        // Get converted value
        convertedValue = pricePerLitre * typeFactor;

        // Format converted value
        convertedValue = convertedValue.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    // Set converted value
    $("#convert-heating-fuel-rate-converted").val(convertedValue);
}

function scrollToError(){
    const firstError = $(".input.error, .page-block-error").first();

    if(firstError.length === 0){
        console.log("No error to scroll to...");
    } else {
        // Scroll up to first error
        $("html, body").animate({
            scrollTop: (firstError.offset().top - 75)
        }, "slow");
    }
}

function addErrorToInput(inputSelector, message) {
    // Get input element
    const input = $(inputSelector);

    // Get input container
    const inputContainer = input.closest(".input");

    // Add error class to input container
    inputContainer.addClass("error");

    // Create error message element
    const errorMessage = $(`<span class="page-block-error">${message}</span>`);

    // Add error message element to input container
    inputContainer.append(errorMessage);
}

function addErrorToAfterElement(elementSelector, message, parentSelector = null) {
    // Get element
    let element = $(elementSelector);

    // Select parent element
    if(parentSelector !== null){
        element = element.closest(parentSelector);
    }
    
    // Create error message element
    const errorMessage = $(`<span class="page-block-error">${message}</span>`);

    // Add error message element after element
    element.after(errorMessage);
}

function calculateTotalConsumption(){
    // Get all consumption inputs
    let inputs = $('.consumption-fields #gasConsumption, #electricityConsumption, .consumption-fields #keroseneConsumption, .consumption-fields #lpgConsumption');
    // Total consumption element
    let totalConsumptionElement = $("#consumption-total .total-figure");

    let totalConsumption = 0;

    // Check to see if required groups variable is set
    if(typeof requiredFormGroups !== 'undefined'){
        // Loop each input
        inputs.each(function(index, element) {
            // Get name
            let name = $(element).attr('name');

            // Get value
            let value = parseFloat($(element).val());

            // If value is not a number, set to 0
            if(isNaN(value)){
                value = 0;
            }

            // Switch name
            switch(name){
                case "electricityConsumption":
                case "gasConsumption":
                case "keroseneConsumption":
                case "lpgConsumption":
                    // Add to total consumption
                    totalConsumption += value;
                    break;
            }
        });

        // Format total consumption
        const formattedTotalConsumption = totalConsumption.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

        // Update total consumption
        totalConsumptionElement.text(formattedTotalConsumption + " kWh");
    }
}

function calculateElsewhereConsumption(){
    // Get all consumption inputs
    let inputs = $('.consumption-split-fields #heatingConsumption, .consumption-split-fields #hotWaterConsumption');

    // All fuel consumption fields
    let allFuelConsumptionFields = $('.consumption-fields #gasConsumption, #electricityConsumption, .consumption-fields #keroseneConsumption, .consumption-fields #lpgConsumption');

    // Total consumption sum
    let totalConsumptionSum = 0;

    // Loop each input
    allFuelConsumptionFields.each(function(index, element) {
        // Get value
        let value = parseFloat($(element).val());

        // If value is not a number, set to 0
        if(isNaN(value)){
            value = 0;
        }

        // Add to total consumption
        totalConsumptionSum += value;
    });

    // Total consumption element
    let totalElsewhereConsumptionElement = $("#consumption-elsewhere-total .total-figure");

    let totalSplitConsumption = 0;

    // Check to see if required groups variable is set
    if(typeof requiredFormGroups !== 'undefined'){
        // Loop each input
        inputs.each(function(index, element) {
            // Get name
            let name = $(element).attr('name');

            // Get value
            let value = parseFloat($(element).val());

            // If value is not a number, set to 0
            if(isNaN(value)){
                value = 0;
            }

            // Switch name
            switch(name){
                case "hotWaterConsumption":
                case "heatingConsumption":
                    // If fossil fuel consumption is required
                    if(requiredFormGroups.indexOf("fossilFuelConsumption") === -1){
                        // Add to total consumption
                        totalSplitConsumption += value;
                    }
                    break;
            }
        });

        const totalConsumptionUsedElsewhere = totalConsumptionSum - totalSplitConsumption;

        // Format total consumption
        const formattedTotalConsumption = totalConsumptionUsedElsewhere.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

        if(totalConsumptionUsedElsewhere < 0){
            // Make text red
            totalElsewhereConsumptionElement.addClass("text-red");
        } else {
            // Remove text red
            totalElsewhereConsumptionElement.removeClass("text-red");
        }

        // Update total consumption
        totalElsewhereConsumptionElement.text(formattedTotalConsumption + " kWh");
    }
}

function updateConsumptionSplit()
{
    if(!$("#consumptionSplitMethodToggle").is(":checked")){
        // Heating consumption
        let heatingConsumption = $("#heatingConsumption");

        // Hot water consumption
        let hotWaterConsumption = $("#hotWaterConsumption");

        // Heating fuel type value
        let heatingFuelTypeValue = $(".wtp-dropdown#heatingFuelType .button .selected").text();

        // Main heating fuel type
        let mainHeatingFuelType = "";

        // Total fuel consumption
        let totalFuelConsumption = 0;

        switch(heatingFuelTypeValue){
            case "Electricity":
                mainHeatingFuelType = "electricity";
                totalFuelConsumption = $("#electricityConsumption").val();
                break;
            case "Gas":
                mainHeatingFuelType = "gas";
                totalFuelConsumption = $("#gasConsumption").val();
                break;
            case "Kerosene":
                mainHeatingFuelType = "gas";
                totalFuelConsumption = $("#keroseneConsumption").val();
                break;
            case "LPG":
                mainHeatingFuelType = "gas";
                totalFuelConsumption = $("#lpgConsumption").val();
                break;
            case "Mix":
                mainHeatingFuelType = "gas";
                totalFuelConsumption = $("#electricityConsumption").val() + $("#gasConsumption").val() + $("#keroseneConsumption").val() + $("#lpgConsumption").val();
                break;
        }

        let newHeatingConsumption;
        let newHotWaterConsumption;

        if(mainHeatingFuelType !== 'electricity'){
            newHeatingConsumption = Math.round(totalFuelConsumption * $("#consumptionSplitFactorFossilHeating").val() * 100) / 100;
            newHotWaterConsumption = Math.round(totalFuelConsumption * $("#consumptionSplitFactorFossilHotWater").val() * 100) / 100;
        } else {
            newHeatingConsumption = Math.round(totalFuelConsumption * $("#consumptionSplitFactorElectricHeating").val() * 100) / 100;
            newHotWaterConsumption = Math.round(totalFuelConsumption * $("#consumptionSplitFactorElectricHotWater").val() * 100) / 100;
        }

        // Update heating consumption
        heatingConsumption.val(newHeatingConsumption);

        // Update hot water consumption
        hotWaterConsumption.val(newHotWaterConsumption);

        calculateTotalConsumption();
        calculateElsewhereConsumption();
    }
}