import Cookies from 'js-cookie';
const body = $('body');

window.technologyPageHelpContactAlert = function() {
    //Check cookie value
    const cookieValue = Cookies.get('helpContactAlertDismissed');

    if(typeof cookieValue === 'undefined' && cookieValue !== 'true'){
        const delayInMilliseconds = 60 * 1000; //60 second

        //Show alert after 60 seconds
        setTimeout(function() {
            $('.help-contact-alert').addClass('show');
        }, delayInMilliseconds);
    }
};

body.on('click', '.help-contact-alert .dismiss-button', function(e) {
    e.preventDefault();

    //Animate away
    $('.help-contact-alert').fadeOut(250, function(){
        $(this).removeClass('show')
    });

    //Set dismissed cookie (for 1 day)
    Cookies.set('helpContactAlertDismissed', 'true', { expires: 1 });
});


// Input suggestions

body.on('focus', '.module-form .field .input-field input', function(e) {
    const suggestions = $(this).closest('.input-field').find('.input-suggestions');

    if (suggestions.find('.input-suggestions-option').length > 0 || suggestions.hasClass('loading')) {
        filterSuggestions($(this));
    }
});

body.on('focusout', '.module-form .field .input-field input', function(e) {
    // Check what the new active element is, if child of input-suggestions, don't close
    if($(e.relatedTarget).closest('.input-suggestions').length > 0){
        return;
    }

    const suggestions = $(this).closest('.input-field').find('.input-suggestions');

    // Close suggestions
    suggestions.removeClass('open');
});

body.on('input', '.module-form .field .input-field input', function(e, isOptionSelected) {
    if (!isOptionSelected) {
        filterSuggestions($(this));
    }
});

body.on('click', '.module-form .field .input-field .input-suggestions .input-suggestions-option', function(e) {
    e.preventDefault();

    // Set suggestion as input value
    const suggestion = $(this).text().trim();
    const inputField = $(this).closest('.input-field').find('input');
    inputField.val(suggestion);

    // Close suggestions
    $(this).closest('.input-suggestions').removeClass('open');

    // Call change and input event on input
    inputField.trigger('change').trigger('input', [true]);
});

function selectSuggestion(inputElement, suggestion){
    // Set suggestion as input value
    inputElement.val(suggestion);

    // Close suggestions
    inputElement.closest('.input-suggestions').removeClass('open');

    // Call change and input event on input
    inputElement.trigger('change').trigger('input', [true]);
}

function filterSuggestions(inputElement){
    const value = inputElement.val().trim();
    const suggestions = inputElement.closest('.input-field').find('.input-suggestions');

    if(suggestions){
        if (value.trim() === "" && suggestions.find(".input-suggestions-option").length > 0) {
            suggestions.find(".input-suggestions-option").removeClass('hidden');
            suggestions.addClass('open');
            return;
        }

        const suggestionsList = suggestions.find('.input-suggestions-list .simplebar-content');
        const optionElements = suggestions.find('.input-suggestions-option');
        const options = [];
        optionElements.each(function(){
            options.push({
                textContent: $(this).text().trim()
            });
        });

        // Fuse search
        const fuse = new Fuse(options, {
            keys: ['textContent'],
            includeScore: true,
        });
        const results = fuse.search(value);

        // Clear suggestions list options
        suggestionsList.find('.input-suggestions-option').remove();

        // Loop through results and add to suggestions
        results.forEach(function(result){
            // Remove value from options array
            const index = options.findIndex(option => option.textContent === result.item.textContent);
            options.splice(index, 1);

            suggestionsList.append('<div class="input-suggestions-option" type="button">'+result.item.textContent+'</div>');
        });

        // Loop through options and add to suggestions, with 'hidden' class
        options.forEach(function(option){
            suggestionsList.append('<div class="input-suggestions-option hidden" type="button">'+option.textContent+'</div>');
        });

        // If no results, show empty state
        if(results.length === 0 && !suggestions.hasClass('loading')){
            suggestions.removeClass('open');
        } else {
            suggestions.addClass('open');
        }
    }
}