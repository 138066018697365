/**
 *
 * Commercial Heat Pump Module JavaScript
 *
 */

let body = $("body");

//Heating calculation method
body.on('click', '.dropdown#calculation-method .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#calculation-method .button .selected").data("value");

    switch(selected){
        case "simple":
            $("#simple-calculations").show();
            $("#detailed-calculations").hide();
            break;
        case "detailed":
            $("#simple-calculations").hide();
            $("#detailed-calculations").show();
            break;
        default:
            $("#simple-calculations").hide();
            $("#detailed-calculations").hide();
            break;
    }
});

//Peak heat loss options
body.on('click', '.dropdown#peak-heat-loss-options .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#peak-heat-loss-options .button .selected").text();

    switch(selected){
        case "Yes":
            $("#manual-peak-heat-loss").show();
            $("#lookup-peak-heat-loss").hide();
            break;
        case "No":
            $("#manual-peak-heat-loss").hide();
            $("#lookup-peak-heat-loss").show();
            break;
        default:
            $("#manual-peak-heat-loss").hide();
            $("#lookup-peak-heat-loss").hide();
            break;
    }
});

//Air changes options
body.on('click', '.dropdown#air-changes-options .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#air-changes-options .button .selected").text();

    switch(selected){
        case "Yes":
            $(".manual-air-changes-field").hide();
            break;
        case "No":
            $(".manual-air-changes-field").show();
            break;
        default:
            $(".manual-air-changes-field").hide();
            break;
    }
});

//System requirements
body.on('click', '.dropdown#systemRequirements .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#systemRequirements .button .selected").text();

    //Tab elements
    let heatingDataTab = $(".module-form .form-progress-v2 .stage-button[data-page=2]");
    let hotWaterDataTab = $(".module-form .form-progress-v2 .stage-button[data-page=3]");
    let airToAirDataTab = $(".module-form .form-progress-v2 .stage-button[data-page=4]");

    //Inputs
    let nonAirToAirInputs = $(".non-air-to-air-inputs");
    let flowTempField = $("#flow-temperature-field");

    switch(selected){
        case "Heating & Hot Water":
            //Enable Heating Data page
            heatingDataTab.removeClass("disabled show-na");

            //Enable Hot Water Data page
            hotWaterDataTab.removeClass("disabled show-na");

            //Disable Air to Air Data page
            airToAirDataTab.addClass("disabled show-na");

            //Show non-air-to-air inputs
            nonAirToAirInputs.show();

            //Show flow temperature field
            flowTempField.show();
            break;
        case "Only Hot Water":
            //Disable Heating Data page
            heatingDataTab.addClass("disabled show-na");

            //Enable Hot Water Data page
            hotWaterDataTab.removeClass("disabled show-na");

            //Disable Air to Air Data page
            airToAirDataTab.addClass("disabled show-na");

            //Show non-air-to-air inputs
            nonAirToAirInputs.show();

            //Hide flow temperature field
            flowTempField.hide();
            break;
        case "Only Heating":
            //Enable Heating Data page
            heatingDataTab.removeClass("disabled show-na");

            //Disable Hot Water Data page
            hotWaterDataTab.addClass("disabled show-na");

            //Disable Air to Air Data page
            airToAirDataTab.addClass("disabled show-na");

            //Show non-air-to-air inputs
            nonAirToAirInputs.show();

            //Show flow temperature field
            flowTempField.show();
            break;
        case "Air to Air":
            //Disable Heating Data page
            heatingDataTab.addClass("disabled show-na");

            //Disable Hot Water Data page
            hotWaterDataTab.addClass("disabled show-na");

            //Enable Air to Air Data page
            airToAirDataTab.removeClass("disabled show-na");

            //Hide non-air-to-air inputs
            nonAirToAirInputs.hide();

            //Hide flow temperature field
            flowTempField.hide();
            break;
        default:
            //Disable Heating Data page
            heatingDataTab.addClass("disabled").removeClass("show-na");

            //Disable Hot Water Data page
            hotWaterDataTab.addClass("disabled").removeClass("show-na");

            //Disable Air to Air Data page
            airToAirDataTab.addClass("disabled show-na");

            //Hide non-air-to-air inputs
            nonAirToAirInputs.hide();

            //Hide flow temperature field
            flowTempField.hide();
            break;
    }
});

//Hot water demand options
body.on('click', '.dropdown#hotwater-demand-options .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#hotwater-demand-options .button .selected").text();
    switch(selected){
        case "Yes":
            $("#hotwater-demand").show();
            $("#hotwater-estimate").hide();
            break;
        case "No":
            $("#hotwater-demand").hide();
            $("#hotwater-estimate").show();
            break;
        default:
            $("#hotwater-demand").hide();
            $("#hotwater-estimate").hide();
            break;
    }
});

//Hot water estimate litres
body.on('click', '.dropdown#hotwater-estimate-litres .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#hotwater-estimate-litres .button .selected").text();

    switch(selected){
        case "Yes":
            $("#hotwater-estimated-litres-input").show();
            $("#hotwater-estimate-fields").hide();
            break;
        case "No":
            $("#hotwater-estimated-litres-input").hide();
            $("#hotwater-estimate-fields").show();
            break;
        default:
            $("#hotwater-estimated-litres-input").hide();
            $("#hotwater-estimate-fields").hide();
            break;
    }
});

//Building heat loss known
body.on('click', '.dropdown#building-heat-loss-known .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#building-heat-loss-known .button .selected").text();
    switch(selected){
        case "Yes":
            $("#building-heat-loss-field").show();
            $("#building-heat-loss-calculator").hide();
            break;
        case "No":
            $("#building-heat-loss-field").hide();
            $("#building-heat-loss-calculator").show();
            break;
        default:
            $("#building-heat-loss-field").hide();
            $("#building-heat-loss-calculator").hide();
            break;
    }
});

//External elements
body.on('input', '.commercial-heat-pump-external-elements .input-row .input.error input', function(e) {
    $(this).parents(".input").removeClass("error");
});

//System efficiency
body.on('input', '.module-form .input-form .slider input[type=range]#systemEfficiency', function(e) {
    $(".systemEfficiencyFigure").text($(this).val() + "%");
});

//Design Margin
body.on('input', '.module-form .input-form .slider input[type=range]#systemDesignMargin', function(e) {
    $(".systemDesignMarginFigure").text($(this).val() + "%");
});

//Toggle input form
body.on('click', '.module-form[data-module=commercialHeatPump] .toggle-commercial-heat-pump-add-room-form', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    //Get state of form
    const isOpen = form.hasClass("open");

    if(isOpen){
        //Slide up
        form.slideUp("slow");

        //Close form
        form.removeClass("open");
    } else {
        //Slide down
        form.slideDown("slow");

        //Open form
        form.addClass("open");
    }
});

//Close input form
body.on('click', '.module-form[data-module=commercialHeatPump] .unit-input-container .unit-input-form .cancel-button', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    //Slide up
    form.slideUp("slow");

    //Close form
    form.removeClass("open");
});

body.on('submit', '#addCommercialHeatPumpRoomForm', function(e) {
    e.preventDefault();

    let form = $(this);

    //Hide errors
    form.find(".errors").hide();

    //Disable submit button
    form.find("button[type=submit]").prop("disabled", true);

    //Serialize form data
    const formData = $(this).serializeArray();

    //Ajax post to add unit
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "addCommercialHeatPumpRoom",
                data: formData
            })
        },
        success: function (data) {
            //Clear form
            form[0].reset();

            //Enable submit button
            form.find("button[type=submit]").prop("disabled", false);

            const formContainer = $(".module-form[data-module=commercialHeatPump] .unit-input-container .unit-input-form");

            //Close form
            formContainer.slideUp("slow");
            formContainer.removeClass("open");

            const unitList = $(`.module-form[data-module=commercialHeatPump] .page[data-page='4'] .inputted-units`);

            //Add unit to list
            unitList.append(`<div class="unit-card"><div class="details"><div class="row"><span class="text larger">${sanitizeHtml(data.identifier)}</span></div><div class="row"><span class="text subtle">Size: ${sanitizeHtml(data.size)} m<sup>2</sup></span></div></div><div class="actions"><button data-id="${sanitizeHtml(data.uuid)}" class="delete-unit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button></div></div>`)

            // //Remove empty state if needed
            unitList.find(".empty-state").addClass("hide").removeClass("error");
        },
        error: function (data) {
            //Enable submit button
            form.find("button[type=submit]").prop("disabled", false);

            //Clear errors
            form.find(".errors .errors-list").html("");

            //Loop through errors
            $.each(data.responseJSON, function (key, value) {
                //Add error to list
                form.find(".errors .errors-list").append("<li class=\"error-item\">" + value + "</li>");
            });

            //Show errors
            form.find(".errors").show();
        }
    });
});

//Delete unit
body.on('click', '.module-form[data-module=commercialHeatPump] .inputted-units .unit-card .delete-unit', function(e) {
    //Confirm delete
    if(!confirm("Are you sure you want to delete this unit?")){
        return;
    }

    //Get unit card
    const unitCard = $(this).closest(".unit-card");

    //Get unit id
    const unitId = $(this).attr("data-id");

    let page = $(this).closest(".page");

    //Get unit type
    let type = "remote";
    if(page.data("page") === 2){
        type = "wired";
    }

    //Remove unit card
    unitCard.remove();

    //Show empty state if needed
    if(page.find(".inputted-units .unit-card").length === 0){
        page.find(".inputted-units .empty-state").removeClass("hide");
    }

    //Ajax post to delete unit
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "removeCommercialHeatPumpRoom",
                type: type,
                id: unitId
            })
        }
    });
});