import { tippy, delegate } from 'tippy.js';
import 'tippy.js/dist/tippy.css';

delegate('body', {
    target: '[data-tippy-content]'
})

delegate('body', {
    target: '.annalizedTooltip',
    content: 'This figure may be higher or lower than what you first entered as it has been annualized.',
});

delegate('body', {
    target: '.account-plan-upgrade-required',
    content: 'Upgrade your account\'s plan to use this feature.',
    placement: 'bottom'
});

// Module Selection

delegate('body', {
    target: '#lightingTooltip',
    content: 'Replace existing inefficient lighting with LED alternatives. In addition, calculate possible motion sensor (PIR) and daylight sensor control system savings.',
    placement: 'bottom'
});

delegate('body', {
    target: '#smartACTooltip',
    content: 'Save energy by improving Air Conditioning (AC) control. Automatically turn-off AC systems in empty rooms. Accurately set occupancy and heating/cooling set point parameters.',
    placement: 'bottom'
});

delegate('body', {
    target: '#refrigerationTooltip',
    content: 'Automatically adjust refrigeration energy consumption according to load requirements.',
    placement: 'bottom'
});

delegate('body', {
    target: '#energyMonitoringTooltip',
    content: 'Monitor incoming energy consumption to identify waste and potential efficiencies.',
    placement: 'bottom'
});

delegate('body', {
    target: '#solarPowerTooltip',
    content: 'Identify electricity generation potential by installing Solar Photovoltaic Panels.',
    placement: 'bottom'
});

delegate('body', {
    target: '#chpTooltip',
    content: 'Combined Heat & Power systems generate electricity. Waste combustion heat is supplied to the building.',
    placement: 'bottom'
});

delegate('body', {
    target: '#heatPumpTooltip',
    content: 'Quickly calculate potential energy and carbon savings that may be achieved upgrading to a domestic Air Source Heat pump.',
    placement: 'bottom'
});

delegate('body', {
    target: '#commercialHeatPumpTooltip',
    content: 'Calculate potential energy and carbon savings that may be achieved by installing Air Source Heat pumps. Users can select simple generic building heat loss factors or enter detailed building fabric heat loss factors to calculate potential savings.',
    placement: 'bottom'
});

delegate('body', {
    target: '#boilerReplacementTooltip',
    content: 'Enter current heating system specifications to be presented with upgrades and potential savings.',
    placement: 'bottom'
});

delegate('body', {
    target: '#bmuTooltip',
    content: 'Boiler Optimiser - optimises the firing pattern of a boiler by creating fewer, but slightly longer, burning periods.',
    placement: 'bottom'
});

delegate('body', {
    target: '#endoThermTooltip',
    content: 'Heating system additive independently proven to increase the surface tension of water, which improves heat transfer rate and efficiency.',
    placement: 'bottom'
});

delegate('body', {
    target: '#smartTRVTooltip',
    content: 'Smart Thermostatic Radiator Valves (TRV) transform each radiator into an independent heating zone. Turn off radiators in empty rooms and reduce heating when open windows/doors are detected.  Adjust via web-based platform, setting room occupancy times and heating set points.',
    placement: 'bottom'
});


//UI
delegate('body', {
    target: '.technology-nav .reset-button button',
    content: 'Reset Survey (Go back to the start)'
});


//Boiler Replacement
delegate('body', {
    target: '#tooltipBoilerReplacementModelName',
    content: 'This can be found either on the boiler data plate or the boiler casing.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBoilerReplacementModelIdentifier',
    content: 'This can be found on the boiler data plate.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBoilerReplacementOutput',
    content: 'This can be found on the boiler data plate.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBoilerReplacementFuelType',
    content: 'Choose the type of fuel the boiler uses.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBoilerReplacementQuantity',
    content: 'Input the quantity of the exact type of boilers.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBoilerReplacementSeasonEfficiency',
    content: 'This can be found either in the boiler documentation or on the manufacturer’s website.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBoilerReplacementManufacturerEfficiencyData',
    content: 'This can be found either in the boiler documentation or on the manufacturer’s website.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBoilerReplacementPartLoad',
    content: 'This can be found either in the boiler documentation or on the manufacturer’s website.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBoilerReplacementEnergyRating',
    content: 'This can be found either on the boiler’s casing, the boiler’s documentation or on the manufacturer’s website.',
    trigger: 'click'
});

//BMU
delegate('body', {
    target: '#tooltipBMUManufacturer',
    content: 'This can be found either on the boiler data plate or the boiler casing.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBMUModelIdentifier',
    content: 'This can be found on the boiler data plate.',
    trigger: 'click'
});


delegate('body', {
    target: '#tooltipBMUOutput',
    content: 'This can be found on the boiler data plate.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBMUFuelType',
    content: 'Choose the type of fuel the boiler uses.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBMUQuantity',
    content: 'Input the quantity of the exact type of boilers.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBMUSeasonEfficiency',
    content: 'This can be found either in the boiler documentation or on the manufacturer’s website.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBMUManufacturerEfficiencyData',
    content: 'This can be found either in the boiler documentation or on the manufacturer’s website.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBMUPartLoad',
    content: 'This can be found either in the boiler documentation or on the manufacturer’s website.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipBMUEnergyRating',
    content: 'This can be found either on the boiler’s casing, the boiler’s documentation or on the manufacturer’s website.',
    trigger: 'click'
});

//CHP

delegate('body', {
    target: '#tooltipCHPBoilerEfficiency',
    content: 'This can be found either on the boiler data plate or in the boiler documentation.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipCHPBoilerEfficiencyLink',
    content: '‘click’ follow the dropdown options and the system will identify your boiler’s efficiency then enter this figure in the efficiency tab.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipCHPWorkWeeks',
    content: 'The number of work weeks within the year.',
    trigger: 'click'
});

//EndoTherm
delegate('body', {
    target: '#tooltipEndoThermManufacturer',
    content: 'This can be found either on the boiler data plate or the boiler casing.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipEndoThermModelIdentifier',
    content: 'This can be found on the boiler data plate.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipEndoThermOutput',
    content: 'This can be found on the boiler data plate.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipEndoThermFuelType',
    content: 'Choose the type of fuel the boiler uses.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipEndoThermQuantity',
    content: 'Input the quantity of the exact type of boilers.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipEndoThermSystemType',
    content: 'Choose what type of heating system is being served.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipEndoThermSeasonEfficiency',
    content: 'This can be found either in the boiler documentation or on the manufacturer’s website.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipEndoThermManufacturerEfficiencyData',
    content: 'This can be found either in the boiler documentation or on the manufacturer’s website.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipEndoThermPartLoad',
    content: 'This can be found either in the boiler documentation or on the manufacturer’s website.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipEndoThermEnergyRating',
    content: 'This can be found either on the boiler’s casing, the boiler’s documentation or on the manufacturer’s website.',
    trigger: 'click'
});


//Lighting
delegate('body', {
    target: '#tooltipLightingFixtureType',
    content: 'Select the light fitting’s fixture type.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingTubeType',
    content: 'Select the light fitting’s tube type.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingLength',
    content: 'Select the light fitting’s length.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingBallast',
    content: 'Select the light fitting’s ballast.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingWattage',
    content: 'Select the light fitting’s wattage.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingLampCapType',
    content: 'Select the light fitting’s lamp cap type.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingUpgradeType',
    content: 'Select the light fitting’s upgrade type.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingColourTemperature',
    content: 'Select the light fitting’s colour temperature.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingFixtureType1',
    content: '<strong>Surface</strong> - A surface mounted light sits on top of a flat surface like the flat part of a ceiling and protrudes away from the surface.<br><br><strong>Recessed</strong> - Recessed lighting is installed directly inside a ceiling, wall, or other surface.<br><br><strong>Cat2 Surface</strong> – A surface mounted light sits on top of a flat surface like the flat part of a ceiling and protrudes away from the surface , Cat2 louvre is a metal diffuser designed to reduce glare for a PC screen<br><br><strong>Cat2 Recessed</strong> – Recessed lighting is installed directly inside a ceiling, wall, or other surface. Cat2 louvre is a metal diffuser designed to reduce glare for a PC screen.<br><br><strong>IP65</strong> - IP rated as "dust tight" and protected against water projected from a nozzle.',
    allowHTML: true,
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingTubeType1',
    content: 'T5 gives the diameter of the <strong>lamp</strong> 5/8th’s of an inch. (1.6 cm)<br><br>T8 gives the diameter of the <strong>lamp</strong> 8/8th’s of an inch (2.54 cm)<br><br>T12 gives the diameter of the <strong>lamp</strong> 12/8th’s of an inch. (3.81 cm)',
    allowHTML: true,
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingStandardQuantity',
    content: 'Input the quantity of standard light fittings.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingEmergencyQuantity',
    content: 'Input the quantity of emergency light fittings.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingRoofReferenceToggle',
    content: 'Tick here to enable location description, this is useful for the installation.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingRoofReference',
    content: 'Input the room number, name, location description.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipLightingDailyUsage',
    content: 'Input the number of weeks per year the light(s) are operational. Day usage not to exceed 17 hours and night usage not to exceed 7 hours.',
    trigger: 'click'
});

//Roof Insulation
delegate('body', {
    target: '#tooltipRoofInsulationType',
    content: 'Use the dropdown to select the type of roof.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipRoofInsulationThickness',
    content: 'Use the slider to select the depth of the existing insulation',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipRoofInsulationArea',
    content: 'Enter the square meterage of the roof area.',
    trigger: 'click'
});

//Solar PV
delegate('body', {
    target: '#tooltipSolarSlope',
    content: 'Use the slider to select the angle of the roof or structure which the panels are to be affixed.<br><i>Note: the average roof slope is 30 degrees.</i>',
    allowHTML: true,
    trigger: 'click'
});


delegate('body', {
    target: '#tooltipSolarShading',
    content: 'Use the slider to indicate how much shading affects the roof area where the solar PV system will be installed.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipSolarRadiation',
    content: 'Enter the radiation figure, enter the postcode to the adjoined map and manually enter the radiation figure here.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipSolarArea',
    content: 'Enter the square meterage of the area.',
    trigger: 'click'
});

//Smart AC Controls Unit
delegate('body', {
    target: '.smartACControlUnoccupiedWorkingHours',
    content: 'Estimated percentage of time during working hours that the space is unoccupied. E.g if a room is a staff room which is used 80% of the time during occupancy hours, and is empty 20% of the time, add 20% here (e.g. system should be turned off when the room is empty).',
    trigger: 'click'
});

delegate('body', {
    target: '.smartACControlEstimatedDutyCycle',
    content: 'Duty cycle percentage depends on many factors. For example, if the ambient temperature is 30°C and the AC set on cooling at 18°C, then the AC will have to work at 100%. If the AC is set at heating with a low ambient outside temperature and the room, as an example, is not well insulated, then again, the AC will have to work hard to reach the temperature set point.  If you do not know the answer, enter an estimated value here.  Our suppliers can simulate this based on the actual installation situation.  Click \'connect with suppliers\' on results page to find out more.  ',
    trigger: 'click'
});

//Building Fabric
delegate('body', {
    target: '#tooltipBuildingFabricFloorArea',
    content: 'You must account for area of all elements and not ignore efficient elements. E.g. if the building has 10m2 of triple glazing, ensure it is added even though it is already efficient.',
    trigger: 'click'
});

//Details
delegate('body', {
    target: '#tooltipDetailsBuildingType',
    content: 'Select the type of building.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipDetailsAssessmentPeriod',
    content: 'Use the calendar to select the annual period over which the consumption relates to NOTE: 365 days required.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipDetailsHeatingEnergy',
    content: 'The proportion of either fossil thermal or electricity consumption used to heat the property.',
    trigger: 'click'
});

delegate('body', {
    target: '#tooltipDetailsGasConsumption',
    content: 'The total gas consumed on site during the assessment period.',
    trigger: 'click'
});



