/**
 *
 * Smart TRV Module JavaScript
 *
 */

let body = $("body");

//Adaptor toggle
body.on('change', '.module-form[data-module="smartTRV"] #adaptorQuantityToggle', function(e) {
    const radiatorQuantity = parseInt($('.module-form[data-module="smartTRV"] .form-pages .page .input-form input#radiatorsQuantity').val());

    let adaptorQuantityElement = $('.module-form[data-module="smartTRV"] .form-pages .page .input-form input#adaptorQuantity');
    const adaptorQuantity = parseInt(adaptorQuantityElement.val());

    if($(this).prop('checked') && radiatorQuantity !== 0 && isNaN(adaptorQuantity)){
        $('.module-form[data-module="smartTRV"] .form-pages .page .input-form input#adaptorQuantity').val(radiatorQuantity);
    }
});

//Radiator adaptor quantity limit
body.on('input', '.module-form[data-module="smartTRV"] .form-pages .page .input-form input#adaptorQuantity, .module-form[data-module="smartTRV"] .form-pages .page .input-form input#radiatorsQuantity', function(e) {
    //Quantities
    const radiatorQuantity = parseInt($('.module-form[data-module="smartTRV"] .form-pages .page .input-form input#radiatorsQuantity').val());
    const adaptorQuantity = parseInt($('.module-form[data-module="smartTRV"] .form-pages .page .input-form input#adaptorQuantity').val());

    //Error message element
    let errorMessage = $(".module-form .form-pages .page .input-form .radiator-adaptor-error-message");

    //Limit adaptor quantity to radiator quantity
    if(adaptorQuantity > radiatorQuantity){
        //Set error message
        errorMessage.text("You can't enter a number higher than the number of radiators (" + radiatorQuantity + ").");

        //Show error message
        errorMessage.show();
    } else {
        //Hide error message
        errorMessage.hide();
    }
});

//SIM Card quantity limit
body.on('input', '.module-form[data-module="smartTRV"] .form-pages .page .input-form input#simCardQuantity, .module-form[data-module="smartTRV"] .form-pages .page .input-form input#radiatorsQuantity', function(e) {
    //Quantities
    const radiatorQuantity = $('.module-form[data-module="smartTRV"] .form-pages .page .input-form input#radiatorsQuantity').val();
    const simCardQuantity = parseInt($('.module-form[data-module="smartTRV"] .form-pages .page .input-form input#simCardQuantity').val());

    //Error message element
    let errorMessage = $(".module-form .form-pages .page .input-form .sim-card-quantity-error-message");

    //Limit sim card quantity to radiator quantity
    if(simCardQuantity > radiatorQuantity){
        //Set error message
        errorMessage.text("You can't enter a number higher than the number of radiators (" + radiatorQuantity + ").");

        //Show error message
        errorMessage.show();
    } else {
        //Hide error message
        errorMessage.hide();
    }
});

//Radiator SIM share slider
body.on('input', '.module-form[data-module="smartTRV"] #shareOfRadsNeedingSims, .module-form[data-module="smartTRV"] #radiatorsQuantity', function(e) {
    //Slider
    let slider = $("#shareOfRadsNeedingSims");

    //Get slider value
    const sliderValue = slider.val();

    //Get number of radiators
    const radiatorQuantity = $("#radiatorsQuantity").val();

    //Calculator number of radiators the share repsents rounded up to a whole number
    const radiatorsNeedingSims = Math.ceil(radiatorQuantity * (sliderValue / 100));

    //Update slider label
    slider.closest(".slider-input").find(".sliderMetaLabel").text(radiatorsNeedingSims + " radiators");
});


body.on('change', '#smartTrvInput .subscription-type-form .radio-inputs input[type=radio]', function(e) {

});