import {apiRequest} from "./index";

/**
 * Get the list of models for a given make
 * @param make
 * @returns {Promise<unknown>}
 */
export function getModeList(make){
    return new Promise(function(resolve, reject){
        // Fetch fixtures for fitting type
        apiRequest('hotWaterTimer', 'modelList', {
            make: make,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            console.log(error);
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

/**
 * Get the monthly storage loss for a given make and model
 * @param make
 * @param model
 * @returns {Promise<unknown>}
 */
export function getStorageLoss(make, model){
    return new Promise(function(resolve, reject){
        // Fetch fixtures for fitting type
        apiRequest('hotWaterTimer', 'getStorageLoss', {
            make: make,
            model: model,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            console.log(error);
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

/**
 * Save the unit
 * @param make
 * @param model
 * @param storageLoss
 * @param quantity
 * @param type
 * @returns {Promise<unknown>}
 */
export function saveUnit(make, model, storageLoss, quantity, type){
    return new Promise(function(resolve, reject){
        apiRequest('hotWaterTimer', 'saveUnit', {
            make: make,
            model: model,
            storageLoss: storageLoss,
            quantity: quantity,
            type: type,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            resolve(error);
        })
    });
}

/**
 * Delete the unit
 * @param uuid
 * @param type
 * @returns {Promise<unknown>}
 */
export function deleteUnit(uuid, type){
    return new Promise(function(resolve, reject){
        apiRequest('hotWaterTimer', 'deleteUnit', {
            uuid: uuid,
            type: type,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            console.log(error);
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}