import tippy from 'tippy.js';
import {formatDistanceToNow} from "date-fns";

// Set timestamps on first load
setTimestamps();

// Update timestamps every 60 seconds
setInterval(setTimestamps, 60000);

function setTimestamps() {
  // Get all elements with the class "smart-timestamp"
  const timestamps = document.querySelectorAll('.smart-timestamp');

  // Loop through each element
  timestamps.forEach((timestamp) => {
    processTimestamps(timestamp);
  });
}


function processTimestamps(timestamp) {
  // Get the timestamp value
  const timestampValue = timestamp.getAttribute('datetime');

  // Create a new Date object from the timestamp value
  const date = new Date(timestampValue);

  const dateObject = new Date(date);

  // If invalid date, return
  if (isNaN(dateObject)) {
    return;
  }

  let dateString = formatDistanceToNow(dateObject, { addSuffix: true });

  // Set the innerHTML of the element to the date in a human-readable format
  timestamp.innerHTML = dateString.charAt(0).toUpperCase() + dateString.slice(1);


  // Add tooltip
  tippy(timestamp, {
    content: date.toLocaleString(),
    placement: 'bottom'
  });
}