import {checkTechnologyData} from "../../functions";
import {saveLightingSimpleForm} from "../../api/lighting";

let body = $("body");

function saveSimpleForm(){
    let floorArea = $("#simpleLightingFloorArea").val();
    let fluorescentShare = $("#simpleLightingFlorescentLightsPercentage").val();
    let buildingId = $("#simpleLightingBuildingId .selected").data('value');
    let fluorescentBenchmark = $("#simpleLightingFlorescentBenchmark").val();
    let ledBenchmark = $("#simpleLightingLEDBenchmark").val();
    let upgradeCost = $("#simpleLightingUpgradeCost").val();

    // Create form
    let formData = {
        floorArea: floorArea,
        fluorescentShare: fluorescentShare,
        buildingId: buildingId,
        fluorescentBenchmark: fluorescentBenchmark,
        ledBenchmark: ledBenchmark,
        upgradeCost: upgradeCost,
    };

    saveLightingSimpleForm(formData).then(function(){
        checkTechnologyData();

        // Disable save button
        $("#simple-lighting-save-form").prop("disabled", true);
    });
}

function checkForChangesInSimpleForm(){
    // Save button
    let saveButton = $("#simple-lighting-save-form");

    // Input fields
    let inputs = $('.module-form[data-module=simpleLighting] input');

    // Dropdowns
    let dropdowns = $('.module-form[data-module=simpleLighting] .dropdown .selected, .module-form[data-module=simpleLighting] .wtp-dropdown .selected');

    let hasChanged = false;

    // Loop each input
    inputs.each(function(){
        // Has saved value data attribute
        if($(this).data("saved-value") !== undefined){
            let savedValue = $(this).data("saved-value");
            let currentValue = $(this).val();

            // Check if value has changed
            if(savedValue.toString() !== currentValue.toString()){
                hasChanged = true;
            }
        }
    });

    // Loop each dropdown
    dropdowns.each(function(){
        // Has saved value data attribute
        if($(this).data("saved-value") !== undefined){
            let savedValue = $(this).data("saved-value");
            let currentValue = $(this).data("value");

            // Check if value has changed
            if(savedValue.toString() !== currentValue.toString()){
                hasChanged = true;
            }
        }
    });

    if(hasChanged){
        // Enable save button
        saveButton.prop("disabled", false);
    } else {
        // Disable save button
        saveButton.prop("disabled", true);
    }
}

function rotate(object, degree) {
    object.css({WebkitTransform: 'rotate(' + degree + 'deg)'});
    object.css({'-moz-transform': 'rotate(' + degree + 'deg)'});
}

//Toggle open assumption fields
body.on('click', '.module-form[data-module=simpleLighting] .edit-assumptions-button', function(e) {
    const fields = $(this).closest(".input-form").find(".assumption-fields");
    const svg = $(this).find("svg");

    //Get state of form
    const isOpen = fields.hasClass("open");

    if(isOpen){
        //Slide up
        fields.slideUp("fast");

        //Close form
        fields.removeClass("open");

        rotate(svg, 0)
    } else {
        //Slide down
        fields.slideDown("fast");

        //Open form
        fields.addClass("open");

        rotate(svg, 180)
    }
});


body.on('click', '.module-form[data-module=simpleLighting] #simpleLightingBuildingId .dropdown-content ul li', function(e) {
    // Get data values
    const fluorescentBenchmark = $(this).data("fluorescent-benchmark");
    const ledBenchmark = $(this).data("led-benchmark");
    const upgradeCost = $(this).data("upgrade-cost");

    // Set values
    $("#simpleLightingFlorescentBenchmark").val(fluorescentBenchmark);
    $("#simpleLightingLEDBenchmark").val(ledBenchmark);
    $("#simpleLightingUpgradeCost").val(upgradeCost);
});

body.on('input', '.module-form[data-module=simpleLighting] input', function(e) {
    checkForChangesInSimpleForm();
});

body.on('click', '.module-form[data-module=simpleLighting] .dropdown .dropdown-content ul li, .module-form[data-module=simpleLighting] .wtp-dropdown .dropdown-content ul li', function(e) {
    checkForChangesInSimpleForm();
});
body.on('click', '.module-form[data-module=simpleLighting] #simple-lighting-save-form', function(e) {
    saveSimpleForm();
});

