import {
    clearPropertySelectionForm,
    createPropertyAndChangeStep,
    createPropertyEnergyLookup, deleteProperty,
    getElectricityMeterLookup,
    getEPCAddresses,
    getGasMeterLookup,
    getPropertyEnergyLookup,
    loadAndChangePropertyFormStep,
    loadCurrentPropertyFormStep,
    propertyElectricityAddressLookup,
    propertyGasAddressLookup,
    refreshPropertyEnergyLookup,
    savePropertyAssumptionsAndRefreshStep, savePropertyManualConsumption, savePropertyManualData,
    savePropertyManualEnergyAndChangeStep,
    setEnergyLookupElectricityAddress, setEnergyLookupElectricityMeter,
    setEnergyLookupGasAddress, setEnergyLookupGasMeter, skipEnergyLookupElectricity, skipEnergyLookupGas,
    submitEPCAddress
} from "./api/property";

const body = $('body');


body.on('input', '#add-new-property-identifier', function (e) {
    // Hide any errors
    $(".add-new-property-form-error.show").removeClass('show');
});

body.on('click', '.add-new-property-form-submit', function (e) {
    let buttonElement = $(this);
    const identifierElement = $("#add-new-property-identifier");
    const identifier = identifierElement.val();
    let errorMessage = $(".add-new-property-form-error");

    // If the identifier is empty, we don't want to submit the form
    if (identifier === '') {
        // Set error message
        errorMessage.text('Please enter a valid identifier.');

        // Show error message
        errorMessage.addClass('show');

        return;
    }

    // Disable button and input
    buttonElement.attr('disabled', true);
    identifierElement.attr('disabled', true);

    createPropertyAndChangeStep(identifier).then(function(response){
        // Container element
        let innerContainer = $(".property-energy-form .page")

        // Set html
        innerContainer.html(response.data.html);
    }).catch(function(error){
        // Enable button and input
        buttonElement.removeAttr('disabled');
        identifierElement.removeAttr('disabled');

        console.log("Error", error)
    });
});

body.on('click', '.property-energy-form .property-dropdown .add-new-property-dropdown-button', function (e) {
    changePage('property-selector-with-focus');
});

body.on('change', '.existing-property-selector .property-list input[type=radio]', function (e) {
    const selectedProperties = $(".existing-property-selector .property-list input[type=radio]:checked");

    // If there are no selected properties, disable the submit button
    if (selectedProperties.length === 0) {
        $(".existing-property-selector .property-selector-continue").attr('disabled', true);
    } else {
        $(".existing-property-selector .property-selector-continue").removeAttr('disabled');
    }
});

body.on('click', '.existing-property-selector .property-selector-continue', function (e) {
    const selectedProperty = $(".existing-property-selector .property-list input[type=radio]:checked");
    const propertyId = selectedProperty.data("property-id");

    loadCurrentPropertyFormStep(propertyId).then(function(response){
        let innerContainer = $(".property-energy-form .page")

        // Set html
        innerContainer.html(response.data.html);
    }).catch(function(error){
        alert('Error loading property form page');
    });
});


// Toggle dropdown options from dropdown button
body.on('click', '.property-energy-form .property-dropdown .dropdown-input .selected', function (e) {
    let dropdown = $(this).closest('.dropdown-input').find('.dropdown-options');

    // Toggle dropdown content
    dropdown.toggleClass('show');
});


// Close dropdown options if click elsewhere
$(document).mouseup(function (e) {
    if ($(e.target).closest(".property-energy-form .property-dropdown .dropdown-input").length === 0) {
        $(".property-energy-form .property-dropdown .dropdown-options").removeClass('show');
    }
});

// Select property
body.on('click', '.property-energy-form .property-dropdown .dropdown-input .dropdown-options .property-list-item .select-button', function (e) {
    let propertyListItem = $(this).closest(".property-list-item");

    if(propertyListItem.hasClass("none-option")){
        // Load property selector page
        clearPropertySelectionForm().then(function(response){
            let innerContainer = $(".property-energy-form .page")

            // Set html
            innerContainer.html(response.data.html);
        }).catch(function(error){
            alert('Error loading property form page');
        });
        return;
    }

    const propertyId = propertyListItem.data('id');

    // Load property page
    loadCurrentPropertyFormStep(propertyId).then(function(response){
        let innerContainer = $(".property-energy-form .page")

        // Set html
        innerContainer.html(response.data.html);
    }).catch(function(error){
        alert('Error loading property form page');
    });
});

// Delete property (dropdown)
body.on('click', '.property-energy-form .property-dropdown .dropdown-input .dropdown-options .property-list-item .delete-property', function (e) {
    const propertyListItem = $(this).closest(".property-list-item");
    const propertyId = propertyListItem.data('id');

    // Alert confirm
    if(!confirm("Are you sure you want to delete this property?")){
        return;
    }

    deleteProperty(propertyId).then(function(response){
        // Load property selector page
        clearPropertySelectionForm().then(function(response){
            // Delete element
            propertyListItem.remove();

            // Get current property id
            const currentPropertyId = $("#selected-property-id").val();

            // If the deleted property is the current property, change page
            if(currentPropertyId === propertyId){
                clearPropertySelectionForm().then(function(response){
                    let innerContainer = $(".property-energy-form .page")

                    // Set html
                    innerContainer.html(response.data.html);

                }).catch(function(error){
                    alert('Error loading property form page');
                })
            }
        }).catch(function(error){
            alert('Error loading property form page');
        });
    }).catch(function(error){
        alert('Error deleting property');
    });
});


// Delete property (selector page)
body.on('click', '.existing-property-selector .property-list .delete-property', function (e) {
    const propertyListItem = $(this).closest(".property-list-item");
    const propertyId = propertyListItem.find('input[type=radio]').data("property-id");
    const propertyWasSelected = propertyListItem.find('input[type=radio]').is(':checked');

    // Alert confirm
    if(!confirm("Are you sure you want to delete this property?")){
        return;
    }

    deleteProperty(propertyId).then(function(response){
        // Load property selector page
        clearPropertySelectionForm().then(function(response){
            // Delete element
            propertyListItem.remove();

            if(propertyWasSelected){
                // Disable continue button
                $(".existing-property-selector .property-selector-continue").attr('disabled', true);
            }
        }).catch(function(error){
            alert('Error loading property form page');
        });
    }).catch(function(error){
        alert('Error deleting property');
    });
});

// Delete property (account page)
body.on('click', '.account-page .properties-list .property-item .delete-button', function (e) {
    const propertyId = $(this).data("property-id");
    const propertyListItem = $(this).closest(".property-item");

    // Alert confirm
    if(!confirm("Are you sure you want to delete this property?")){
        return;
    }

    deleteProperty(propertyId).then(function(response){
        // Load property selector page
        clearPropertySelectionForm().then(function(response){
            // Delete element
            propertyListItem.remove();
        }).catch(function(error){
            alert('Error loading property form page');
        });
    }).catch(function(error){
        alert('Error deleting property');
    });
});


body.on('click', '.property-energy-form .purchase-property-energy-lookup', function (e) {
    let modal = $(".modal[data-modal='propertyEnergyLookup']");

    // Open modal
    modal.addClass("open");

    // Hide all pages
    modal.find(".page").removeClass("show");

    // Show input page
    modal.find(".page.input-page").addClass("show");
});

body.on('click', '.property-energy-form .continue-property-energy-lookup', function (e) {
    let modal = $(".modal[data-modal='propertyEnergyLookup']");

    // Open modal
    modal.addClass("open");

    // Hide all pages
    modal.find(".page").removeClass("show");

    // Show data lookup page
    modal.find(".page.data-lookup-page").addClass("show");

    loadPropertyEnergyLookup();
});


body.on('click', '.property-energy-lookup .close-modal', function (e) {
    const modal = $(this).closest(".modal");
    modal.removeClass("open");
});

body.on('click', '.property-energy-lookup .confirm-button', function (e) {
    const modal = $(this).closest(".modal");
    const isInputPage = modal.find(".page.input-page").hasClass("show");

    if(isInputPage){
        const postcode = $("#energy-lookup-property-postcode").val();
        const ownerName = $("#energy-lookup-property-owners-name").val();
        const propertyId = $("#selected-property-id").val();

        createPropertyEnergyLookup(propertyId, postcode, ownerName).then(function(response){
            // Hide input page
            $(".property-energy-lookup .page.input-page").removeClass("show");

            // Open data-looking-up page
            $(".property-energy-lookup .page.data-lookup-page").addClass("show");

            // Save energy lookup id
            $("#selected-property-energy-lookup-id").val(response.data.energyLookup.id);

            // Update lookup steps
            propertyLookupUpdateSteps(
                response.data.energyLookup.gasAddressStatus,
                response.data.energyLookup.gasAddressOptions,
                response.data.energyLookup.gasMeterStatus,
                response.data.energyLookup.gasMeterOptions,
                response.data.energyLookup.electricityAddressStatus,
                response.data.energyLookup.electricityAddressOptions,
                response.data.energyLookup.electricityMeterStatus,
                response.data.energyLookup.electricityMeterOptions
            );
        }).catch(function(error){
            alert("Error: Creating property energy lookup");
        });
    }
});

body.on('click', '.property-energy-lookup .confirm-energy-lookup-fuel-address-selection', function (e){
   let stepName = $(this).closest(".step").data('step-name');
   const propertyId = $("#selected-property-id").val();
   const energyLookupId = $("#selected-property-energy-lookup-id").val();

    switch (stepName) {
        case "gas-address-lookup":
            let gasSelectedAddress = $(".property-energy-lookup .page.data-lookup-page .step[data-step-name='gas-address-lookup'] input[name='addressSelectorOption']:checked");
            let gasAddressId = gasSelectedAddress.val();

            setEnergyLookupGasAddress(energyLookupId, gasAddressId).then(function(response){
                propertyLookupUpdateSteps(
                    response.data.energyLookup.gasAddressStatus,
                    response.data.energyLookup.gasAddressOptions,
                    response.data.energyLookup.gasMeterStatus,
                    response.data.energyLookup.gasMeterOptions,
                    response.data.energyLookup.electricityAddressStatus,
                    response.data.energyLookup.electricityAddressOptions,
                    response.data.energyLookup.electricityMeterStatus,
                    response.data.energyLookup.electricityMeterOptions
                );
            }).catch(function(error){
                console.log("Error", error)
                alert("Error: Setting energy lookup gas address")
            })
            break;
        case "electricity-address-lookup":
            let electricitySelectedAddress = $(".property-energy-lookup .page.data-lookup-page .step[data-step-name='electricity-address-lookup'] input[name='addressSelectorOption']:checked");
            let electricityAddressId = electricitySelectedAddress.val();

            setEnergyLookupElectricityAddress(energyLookupId, electricityAddressId).then(function(response){
                propertyLookupUpdateSteps(
                    response.data.energyLookup.gasAddressStatus,
                    response.data.energyLookup.gasAddressOptions,
                    response.data.energyLookup.gasMeterStatus,
                    response.data.energyLookup.gasMeterOptions,
                    response.data.energyLookup.electricityAddressStatus,
                    response.data.energyLookup.electricityAddressOptions,
                    response.data.energyLookup.electricityMeterStatus,
                    response.data.energyLookup.electricityMeterOptions
                );
            }).catch(function(error){
                console.log("Error", error)
                alert("Error: Setting energy lookup electricity address")
            })
            break;

        default:
            console.log("Error: Unknown step name", stepName)
            break;
   }
});

body.on('click', '.property-energy-lookup .skip-energy-lookup-fuel-selection', function (e){
    let stepName = $(this).closest(".step").data('step-name');
    const energyLookupId = $("#selected-property-energy-lookup-id").val();

    switch (stepName) {
        case "gas-address-lookup":
        case "gas-meter-lookup":
            skipEnergyLookupGas(energyLookupId).then(function(response){
                propertyLookupUpdateSteps(
                    response.data.energyLookup.gasAddressStatus,
                    response.data.energyLookup.gasAddressOptions,
                    response.data.energyLookup.gasMeterStatus,
                    response.data.energyLookup.gasMeterOptions,
                    response.data.energyLookup.electricityAddressStatus,
                    response.data.energyLookup.electricityAddressOptions,
                    response.data.energyLookup.electricityMeterStatus,
                    response.data.energyLookup.electricityMeterOptions
                );
            }).catch(function(error){
                alert("Error: Skipping energy lookup gas section")
            })
            break;
        case "electricity-address-lookup":
        case "electricity-meter-lookup":
            skipEnergyLookupElectricity(energyLookupId).then(function(response){
                propertyLookupUpdateSteps(
                    response.data.energyLookup.gasAddressStatus,
                    response.data.energyLookup.gasAddressOptions,
                    response.data.energyLookup.gasMeterStatus,
                    response.data.energyLookup.gasMeterOptions,
                    response.data.energyLookup.electricityAddressStatus,
                    response.data.energyLookup.electricityAddressOptions,
                    response.data.energyLookup.electricityMeterStatus,
                    response.data.energyLookup.electricityMeterOptions
                );
            }).catch(function(error){
                alert("Error: Skipping energy lookup electricity address")
            })
            break;
        default:
            console.log("Error: Unknown step name", stepName)
            break;
    }
});

body.on('click', '.property-energy-lookup .confirm-energy-lookup-fuel-meter-selection', function (e){
    let stepName = $(this).closest(".step").data('step-name');
    const propertyId = $("#selected-property-id").val();
    const energyLookupId = $("#selected-property-energy-lookup-id").val();

    switch (stepName) {
        case "gas-meter-lookup":
            let gasSelectedMeter = $(".property-energy-lookup .page.data-lookup-page .step[data-step-name='gas-meter-lookup'] input[name='meterSelectorOption']:checked");
            let gasMeterId = gasSelectedMeter.val();

            setEnergyLookupGasMeter(energyLookupId, gasMeterId).then(function(response){
                propertyLookupUpdateSteps(
                    response.data.energyLookup.gasAddressStatus,
                    response.data.energyLookup.gasAddressOptions,
                    response.data.energyLookup.gasMeterStatus,
                    response.data.energyLookup.gasMeterOptions,
                    response.data.energyLookup.electricityAddressStatus,
                    response.data.energyLookup.electricityAddressOptions,
                    response.data.energyLookup.electricityMeterStatus,
                    response.data.energyLookup.electricityMeterOptions
                );
            }).catch(function(error){
                console.log("Error", error)
                alert("Error: Setting energy lookup gas meter")
            })
            break;
        case "electricity-meter-lookup":
            let electricitySelectedMeter = $(".property-energy-lookup .page.data-lookup-page .step[data-step-name='electricity-meter-lookup'] input[name='meterSelectorOption']:checked");
            let electricityMeterId = electricitySelectedMeter.val();

            setEnergyLookupElectricityMeter(energyLookupId, electricityMeterId).then(function(response){
                propertyLookupUpdateSteps(
                    response.data.energyLookup.gasAddressStatus,
                    response.data.energyLookup.gasAddressOptions,
                    response.data.energyLookup.gasMeterStatus,
                    response.data.energyLookup.gasMeterOptions,
                    response.data.energyLookup.electricityAddressStatus,
                    response.data.energyLookup.electricityAddressOptions,
                    response.data.energyLookup.electricityMeterStatus,
                    response.data.energyLookup.electricityMeterOptions
                );
            }).catch(function(error){
                console.log("Error", error)
                alert("Error: Setting energy lookup electricity meter")
            })
            break;

        default:
            console.log("Error: Unknown step name", stepName)
            break;
    }
});


body.on('input', '.property-energy-lookup .data-lookup-page .address-selector .address-search-bar input', function (e) {
    const addressSelectorContainer = $(this).closest(".address-selector");
    const searchValue = $(this).val().toLowerCase();
    const addressOptions = addressSelectorContainer.find(".address-selector-options .address-selector-option");

    // Loop through options
    for (let i = 0; i < addressOptions.length; i++) {
        let option = $(addressOptions[i]);
        let optionText = option.find("label").text().toLowerCase();

        if(optionText.includes(searchValue)){
            option.removeClass("hide");
        } else {
            option.addClass("hide");
        }
    }
});


body.on('click', '.property-energy-lookup .purchase-form input[type="checkbox"]', function (e) {
    propertyLookupInputFormCheckboxChange();
});

body.on('input', '.property-energy-lookup #energy-lookup-property-postcode, .property-energy-lookup #energy-lookup-property-owners-name', function (e) {
    propertyLookupInputFormCheckboxChange();
});

body.on('click', '.property-energy-form .minimise-button', function (e) {
    toggleMinimise();
});

body.on('click', '.property-energy-form .epc-lookup-postcode-search', function (e) {
    const button = $(this);
    let postcode = $(".property-energy-form #epc-lookup-postcode").val();
    let noEpcReport = $(".property-energy-form .epc-not-found-for-address");

    // Hide no epc report
    noEpcReport.removeClass("show");

    // Disable button
    button.attr("disabled", true);

    getEPCAddresses(postcode).then(function(response){
        // Enable button
        button.removeAttr("disabled");

        if(response.success){
            let epcLookup = $(".property-energy-form .property-details-epc-look-up");
            let epcNoneFound = $(".property-energy-form .epc-address-none-found");

            if(response.data.epcAddresses.length > 0){

                let html = "<li data-value='0'>Select an option</li>";

                // Loop through addresses
                for (let i = 0; i < response.data.epcAddresses.length; i++) {
                    let address = response.data.epcAddresses[i].fullAddress;
                    html += `<li data-value="${address}">${address}</li>`;
                }

                // Set dropdown options
                epcLookup.find("#epc-address-selector-dropdown .dropdown-content .simplebar-content").html(html)

                // Hide none found
                epcNoneFound.removeClass("show");

                // Show dropdown
                epcLookup.find(".epc-address-selector").addClass("show");
            } else {
                // Show none found
                epcNoneFound.addClass("show");

                // Hide dropdown
                epcLookup.find(".epc-address-selector").removeClass("show");
            }
        } else {
            alert("Error: Getting EPC addresses (1)")
        }
    }).catch(function(error){
      alert("Error: Getting EPC addresses")
    })
});

body.on('click', '.property-energy-form .submit-epc-address', function (e) {
    let noEpcReport = $(".property-energy-form .epc-not-found-for-address");

    // Hide no epc report
    noEpcReport.removeClass("show");

    // get postcode
    const postcode = $(".property-energy-form #epc-lookup-postcode").val();

    // Get address
    const address = $("#epc-address-selector-dropdown .selected").data('value');

    const propertyId = $("#selected-property-id").val();

    submitEPCAddress(postcode, address, propertyId).then(function(response){
        // Change page
        changePage('property-overview');
    }).catch(function(error){
        // Show no epc report
        noEpcReport.addClass("show");
    })
});

body.on('click', '.property-energy-form .page .save-property-assumptions', function (e) {
    const button = $(this);
    const propertyOverviewPage = $(".property-energy-form .page");

    // Values
    const electricityFuelRate = propertyOverviewPage.find("#overview-electricity-rate").val();
    const heatingFuelRate = propertyOverviewPage.find("#overview-heating-rate").val();
    const buildingType = propertyOverviewPage.find("#overview-epc-building-type .selected").data("value");
    const floorArea = propertyOverviewPage.find("#overview-floor-area").val();

    // Disable button
    button.attr("disabled", true);

    const propertyId = $("#selected-property-id").val();

    savePropertyAssumptionsAndRefreshStep(propertyId, electricityFuelRate, heatingFuelRate, buildingType, floorArea).then(function(response){
        // Container element
        let innerContainer = $(".property-energy-form .page")

        // Set html
        innerContainer.html(response.data.html);
    }).catch(function(error){
        alert("Error: Saving property assumptions")

        // Enable button
        button.removeAttr("disabled");
    });
});

body.on('click', '.property-energy-form .save-manual-property-energy-data', function (e) {
    const propertyId = $("#selected-property-id").val();
    const button = $(this);
    const page = $(".property-energy-form .manual-property-energy");

    // Form values
    const assessmentStartDate = page.find("#manual-energy-assessment-period-start").val();
    const assessmentEndDate = page.find("#manual-energy-assessment-period-end").val();
    const electricityConsumption = page.find("#manual-energy-electricity-consumption").val();
    const heatingConsumption = page.find("#manual-energy-heating-consumption").val();
    const heatingFuelType = page.find("#manual-energy-heating-fuel-type .selected").data('value');

    // Disable button
    button.attr("disabled", true);


    savePropertyManualEnergyAndChangeStep(propertyId, electricityConsumption, heatingFuelType, heatingConsumption, assessmentStartDate, assessmentEndDate).then(function(response){
        // Container element
        let innerContainer = $(".property-energy-form .page")

        // Set html
        innerContainer.html(response.data.html);
    })
});

body.on('click', '.property-energy-form .save-manual-building-data', function(e){
    const button = $(this);
    const page = $(".property-energy-form .manual-property-details");

    const propertyId = $("#selected-property-id").val();

    // Disable button
    button.attr("disabled", true);

    // Form values
    const addressLine1 = page.find("#manual-address-line-1").val();
    const addressLine2 = page.find("#manual-address-line-2").val();
    const addressCity = page.find("#manual-address-city").val();
    const addressPostcode = page.find("#manual-address-postcode").val();
    const floorArea = page.find("#manual-floor-area").val();
    const buildingType = page.find("#manual-building-type .selected").data('value');

    savePropertyManualData(propertyId, addressLine1, addressLine2, addressCity, addressPostcode, buildingType, floorArea).then(function(response){
        // Container element
        let innerContainer = $(".property-energy-form .page")

        // Set html
        innerContainer.html(response.data.html);
    }).catch(function(error){
        // Enable button
        button.removeAttr("disabled");

        alert("Error: Saving property data")
    })
});

body.on('click', '.property-energy-form .toggle-manual-edit-input', function(e) {
    // Closest manual edit input
    let inputSection = $(this).parents(".manual-edit-footer").children(".manual-edit-input-section");

    // Toggle show
    inputSection.slideToggle("fast");
});

body.on('click', '.property-energy-form .statistic-card .save-manual-consumption', function(e) {
    const propertyId = $("#selected-property-id").val();
    let button = $(this);
    let inputElement = $(this).closest(".input-with-action").find(".wtp-field .wtp-input");
    let type = inputElement.is("#overview-manual-heating-consumption") ? "heating" : "electricity";
    let consumption = inputElement.val();

    savePropertyManualConsumption(propertyId, type, consumption).then(function(response){
        // Container element
        let innerContainer = $(".property-energy-form .page")

        // Set html
        innerContainer.html(response.data.html);
    }).catch(function(error){
        // Enable button
        button.removeAttr("disabled");

        alert("Error: Saving manual consumption data")
    })
});

body.on('click', '.property-energy-form .refresh-data-button', function(e){

    // Alert confirm
    if(!confirm("Are you sure you want to refresh the data?")){
        return;
    }

    const button = $(this);
    const propertyId = $("#selected-property-id").val();

    // Disable button
    button.attr("disabled", true);

    refreshPropertyEnergyLookup(propertyId).then(function(response){
        // Container element
        let innerContainer = $(".property-energy-form .page")

        // Set html
        innerContainer.html(response.data.html);

        let modal = $(".modal[data-modal='propertyEnergyLookup']");

        // Open modal
        modal.addClass("open");
    }).catch(function(error){
        alert("Error: Refreshing property energy lookup")
    });
});

// Change of dropdown value - manual energy form
body.on('wtp-dropdown-change', '.property-energy-form .manual-property-energy .electricity-heating-form .wtp-dropdown', function (e, selectedText, hiddenValue) {
    validateManualPropertyEnergyForm();
});

// Change of input value - manual energy form
body.on('input', '.property-energy-form .manual-property-energy .electricity-heating-form .wtp-input', function (e) {
    validateManualPropertyEnergyForm();
});

// Change of dropdown value - manual property form
body.on('wtp-dropdown-change', '.property-energy-form .manual-property-details .manual-property-details-form .wtp-dropdown', function (e, selectedText, hiddenValue) {
    validateManualPropertyDetailsForm();
});

// Change of input value - manual property form
body.on('input', '.property-energy-form .manual-property-details .manual-property-details-form .wtp-input', function (e) {
    validateManualPropertyDetailsForm();
})


body.on('change', '.property-energy-form .address-selector-options input[type=radio]', function (e) {
    const confirmButton = $(this).closest(".address-selector").find(".confirm-energy-lookup-fuel-address-selection");

    // Enable button
    confirmButton.removeAttr("disabled");
})

body.on('change', '.property-energy-form .meter-selector-options input[type=radio]', function (e) {
    const confirmButton = $(this).closest(".meter-selector").find(".confirm-energy-lookup-fuel-meter-selection");

    // Enable button
    confirmButton.removeAttr("disabled");
})


function toggleMinimise(){
    $(".property-energy-form .minimise-button").toggleClass("maximise");
    $(".property-energy-form .overlay-collapse").toggleClass("show");
    $(".property-energy-form .page").toggleClass("collapsed");
}

function changePage(stepName){
    let focusOnPropertyIdentifier = false;
    const propertyId = $("#selected-property-id").val();

    if(stepName === 'property-selector-with-focus'){
        stepName = 'property-selector';
        focusOnPropertyIdentifier = true;
    }

    if(stepName === 'property-selector'){
        clearPropertySelectionForm(propertyId, stepName).then(function(response) {
            let innerContainer = $(".property-energy-form .page")

            // Set html
            innerContainer.html(response.data.html);

            // Focus on property identifier
            if(focusOnPropertyIdentifier){
                $("#add-new-property-identifier").focus();
            }
        }).catch(function(error){
            alert("Error: Changing page")
        });
        return;
    }

    loadAndChangePropertyFormStep(propertyId, stepName).then(function(response) {
        let innerContainer = $(".property-energy-form .page")

        // Set html
        innerContainer.html(response.data.html);
    }).catch(function(error){
        alert("Error: Changing page")
    });
}


function loadPropertyEnergyLookup(){
    const propertyId = $("#selected-property-id").val();

    getPropertyEnergyLookup(propertyId).then(function(response){
        if(response.success){
            // Set property energy lookup data
            const propertyEnergyLookupData = response.data.energyLookup;

            // Update lookup steps
            propertyLookupUpdateSteps(
                propertyEnergyLookupData.gasAddressStatus,
                propertyEnergyLookupData.gasAddressOptions,
                propertyEnergyLookupData.gasMeterStatus,
                propertyEnergyLookupData.gasMeterOptions,
                propertyEnergyLookupData.electricityAddressStatus,
                propertyEnergyLookupData.electricityAddressOptions,
                propertyEnergyLookupData.electricityMeterStatus,
                propertyEnergyLookupData.electricityMeterOptions
            );
        } else {
            console.log("Error", response)
            alert("Error: Getting property energy lookup");
        }
    }).catch(function(error){
        console.log("Error", error)
        alert("Error: Getting property energy lookup")
    });
}


export function propertyLookupUpdateSteps(gasAddressStatus, gasAddressOptions, gasMeterStatus, gasMeterOptions, electricityAddressStatus, electricityAddressOptions, electricityMeterStatus, electricityMeterOptions){
    let steps = $(".property-energy-lookup .data-lookup-steps");
    const propertyId = $("#selected-property-id").val();
    const energyLookupId = $("#selected-property-energy-lookup-id").val();
    let gasAddressLookup = steps.find(".step[data-step-name='gas-address-lookup']");
    let gasMeterLookup = steps.find(".step[data-step-name='gas-meter-lookup']");
    let electricityAddressLookup = steps.find(".step[data-step-name='electricity-address-lookup']");
    let electricityMeterLookup = steps.find(".step[data-step-name='electricity-meter-lookup']");

    // Hide all step icons
    steps.find(".icon.show").removeClass("show");

    // Gas address lookup
    switch(gasAddressStatus){
        case "ready":
            gasAddressLookup.find(".icon.spinning").addClass("show");
            gasAddressLookup.find(".step-content").removeClass("open");
            gasAddressLookup.find(".text .subtext").text("");

            propertyGasAddressLookup(energyLookupId).then(function(response){
                // Clear icons
                gasAddressLookup.find(".icon.show").removeClass("show");

                // Show error icon
                gasAddressLookup.find(".icon.error").addClass("show");

                let addresses = response.data.gasAddresses;

                // Clear options
                gasAddressLookup.find(".step-content .address-selector-options").html("");

                // Loop through options
                for (let i = 0; i < addresses.length; i++) {
                    let address = addresses[i];

                    // Create option
                    let option = $(`<div class='address-selector-option'><input type='radio' id='addressSelectorOption${address.addressId}' name='addressSelectorOption' value='${address.addressId}'><label for="addressSelectorOption${address.addressId}">${address.addressAsLine}</label></div>`);

                    // Add option to options
                    gasAddressLookup.find(".step-content .address-selector-options").append(option);
                }

                // Show options
                gasAddressLookup.find(".step-content").addClass("open");


            }).catch(function(error){
              alert("Error: Getting property gas address lookup")
            })
            break;
        case "option-selection":
            gasAddressLookup.find(".icon.error").addClass("show");
            gasAddressLookup.find(".step-content").addClass("open");

            // Clear options
            gasAddressLookup.find(".step-content .address-selector-options").html("");

            // Show address selector
            gasAddressLookup.find(".step-content .address-selector").removeClass("hidden");

            // Loop through options
            for (let i = 0; i < gasAddressOptions.length; i++) {
                let address = gasAddressOptions[i];

                // Create option
                let option = $(`<div class='address-selector-option'><input type='radio' id='addressSelectorOption${address.addressId}' name='addressSelectorOption' value='${address.addressId}'><label for="addressSelectorOption${address.addressId}">${address.addressAsLine}</label></div>`);

                // Add option to options
                gasAddressLookup.find(".step-content .address-selector-options").append(option);
            }

            // Show options
            gasAddressLookup.find(".step-content").addClass("open");
            gasAddressLookup.find(".text .subtext").text("Select an option");
            break;
        case "error":
            gasAddressLookup.find(".icon.error").addClass("show");
            gasAddressLookup.find(".step-content").removeClass("open");
            gasAddressLookup.find(".text .subtext").text("There was a problem with this step");
            break;
        case "waiting":
            gasAddressLookup.find(".icon.waiting").addClass("show");
            gasAddressLookup.find(".step-content").removeClass("open");
            gasAddressLookup.find(".text .subtext").text("");
            break;
        case "skipped":
            gasAddressLookup.find(".icon.skipped").addClass("show");
            gasAddressLookup.find(".step-content").removeClass("open");
            gasAddressLookup.find(".text .subtext").text("Step skipped");
            break;
        case "none-found":
            gasAddressLookup.find(".icon.skipped").addClass("show");
            gasAddressLookup.find(".step-content").removeClass("open");
            gasAddressLookup.find(".text .subtext").text("No meters found at address");
            break;
        case "complete":
            gasAddressLookup.find(".icon.success").addClass("show");
            gasAddressLookup.find(".step-content").removeClass("open");
            gasAddressLookup.find(".text .subtext").text("Step complete");
            break;
        default:
            console.log("Error: Unknown gas address lookup status", gasAddressLookup)
            break;
    }

    // Gas meter lookup
    switch(gasMeterStatus){
        case "ready":
            gasMeterLookup.find(".icon.spinning").addClass("show");
            gasMeterLookup.find(".step-content").removeClass("open");
            gasMeterLookup.find(".text .subtext").text("");

            getGasMeterLookup(energyLookupId).then(function(response){
                if(response.success){
                    // Update lookup steps
                    propertyLookupUpdateSteps(
                        response.data.energyLookup.gasAddressStatus,
                        response.data.energyLookup.gasAddressOptions,
                        response.data.energyLookup.gasMeterStatus,
                        response.data.energyLookup.gasMeterOptions,
                        response.data.energyLookup.electricityAddressStatus,
                        response.data.energyLookup.electricityAddressOptions,
                        response.data.energyLookup.electricityMeterStatus,
                        response.data.energyLookup.electricityMeterOptions
                    );
                } else {
                    alert("Error: Getting gas meter lookup")
                }
                console.log(response)
            }).catch(function(error){
                alert("Error: looking up gas meter")
            });
            break;
        case "option-selection":
            gasMeterLookup.find(".icon.error").addClass("show");
            gasMeterLookup.find(".step-content").addClass("open");

            // Clear options
            gasMeterLookup.find(".step-content .address-selector-options").html("");

            // Show address selector
            gasMeterLookup.find(".step-content .address-selector").removeClass("hidden");

            // Delete options
            gasMeterLookup.find(".step-content .meter-selector-options").html("");

            // Loop through options
            for (let i = 0; i < gasMeterOptions.length; i++) {
                let meter = gasMeterOptions[i];

                // Create option
                let option = $(`<div class='meter-selector-option'><input type='radio' id='meterSelectorOption${meter.uuid}' name='meterSelectorOption' value='${meter.uuid}'><label for="meterSelectorOption${meter.uuid}"><span class="meter-index">Meter #${meter.index}</span><div class="content"><div class="consumption-figure">${meter.consumption} kWh</div><div class="description">${meter.designation} - ${meter.supplier}</div></div></label></div>`);

                // Add option to options
                gasMeterLookup.find(".step-content .meter-selector-options").append(option);
            }

            // If only one option, select it
            if (gasMeterOptions.length === 1){
                // Select option
                gasMeterLookup.find(".meter-selector-option input[type=radio]").prop("checked", true);

                // Enable button
                gasMeterLookup.find(".confirm-energy-lookup-fuel-meter-selection").removeAttr("disabled");
            }

            // Show options
            gasMeterLookup.find(".step-content").addClass("open");
            gasMeterLookup.find(".text .subtext").text("Select an option");
            break;
        case "error":
            gasMeterLookup.find(".icon.error").addClass("show");
            gasMeterLookup.find(".step-content").removeClass("open");
            gasMeterLookup.find(".text .subtext").text("There was a problem with this step");
            break;
        case "waiting":
            gasMeterLookup.find(".icon.waiting").addClass("show");
            gasMeterLookup.find(".step-content").removeClass("open");
            gasMeterLookup.find(".text .subtext").text("");
            break;
        // Step complete
        case "complete":
            gasMeterLookup.find(".icon.success").addClass("show");
            gasMeterLookup.find(".step-content").removeClass("open");
            gasMeterLookup.find(".text .subtext").text("Step complete");
            break;
        case "skipped":
            gasMeterLookup.find(".icon.skipped").addClass("show");
            gasMeterLookup.find(".step-content").removeClass("open");
            gasMeterLookup.find(".text .subtext").text("Step skipped");
            break;
        case "none-found":
            gasMeterLookup.find(".icon.skipped").addClass("show");
            gasMeterLookup.find(".step-content").removeClass("open");
            gasMeterLookup.find(".text .subtext").text("No meters found at address");
            break;
        default:
            console.log("Error: Unknown gas meter lookup status", gasMeterLookup)
            break;
    }

    // Electricity address lookup
    switch(electricityAddressStatus){
        // Ready for API call
        case "ready":
            electricityAddressLookup.find(".icon.spinning").addClass("show");
            electricityAddressLookup.find(".step-content").removeClass("open");
            electricityAddressLookup.find(".text .subtext").text("");

            propertyElectricityAddressLookup(energyLookupId).then(function(response){
                // Clear icons
                electricityAddressLookup.find(".icon.show").removeClass("show");

                // Show error icon
                electricityAddressLookup.find(".icon.error").addClass("show");

                let addresses = response.data.electricityAddresses;

                // Clear options
                electricityAddressLookup.find(".step-content .address-selector-options").html("");

                // Loop through options
                for (let i = 0; i < addresses.length; i++) {
                    let address = addresses[i];

                    // Create option
                    let option = $(`<div class='address-selector-option'><input type='radio' id='addressSelectorOption${address.addressId}' name='addressSelectorOption' value='${address.addressId}'><label for="addressSelectorOption${address.addressId}">${address.addressAsLine}</label></div>`);

                    // Add option to options
                    electricityAddressLookup.find(".step-content .address-selector-options").append(option);
                }

                // Show options
                electricityAddressLookup.find(".step-content").addClass("open");


            }).catch(function(error){
                alert("Error: Getting property gas address lookup")
            })
            break;
        // User needs to select an option
        case "option-selection":
            electricityAddressLookup.find(".icon.error").addClass("show");
            electricityAddressLookup.find(".step-content").addClass("open");

            // Clear options
            electricityAddressLookup.find(".step-content .address-selector-options").html("");

            // Loop through options
            for (let i = 0; i < electricityAddressOptions.length; i++) {
                let address = electricityAddressOptions[i];

                // Create option
                let option = $(`<div class='address-selector-option'><input type='radio' id='addressSelectorOption${address.addressId}' name='addressSelectorOption' value='${address.addressId}'><label for="addressSelectorOption${address.addressId}">${address.addressAsLine}</label></div>`);

                // Add option to options
                electricityAddressLookup.find(".step-content .address-selector-options").append(option);
            }

            // Show options
            electricityAddressLookup.find(".step-content").addClass("open");
            electricityAddressLookup.find(".text .subtext").text("Select an option");
            break;
        case "error":
            electricityMeterLookup.find(".icon.error").addClass("show");
            electricityMeterLookup.find(".step-content").removeClass("open");
            electricityAddressLookup.find(".text .subtext").text("There was a problem with this step");
            break;
        case "waiting":
            electricityAddressLookup.find(".icon.waiting").addClass("show");
            electricityAddressLookup.find(".step-content").removeClass("open");
            electricityAddressLookup.find(".text .subtext").text("");
            break;
        case "complete":
            electricityAddressLookup.find(".icon.success").addClass("show");
            electricityAddressLookup.find(".step-content").removeClass("open");
            electricityAddressLookup.find(".text .subtext").text("Step complete");
            break;
        case "skipped":
            electricityAddressLookup.find(".icon.skipped").addClass("show");
            electricityAddressLookup.find(".step-content").removeClass("open");
            electricityAddressLookup.find(".text .subtext").text("Step skipped");
            break;
        case "none-found":
            electricityAddressLookup.find(".icon.skipped").addClass("show");
            electricityAddressLookup.find(".step-content").removeClass("open");
            electricityAddressLookup.find(".text .subtext").text("No meters found at address");
            break;
        default:
            console.log("Error: Unknown electricity address lookup status", electricityAddressLookup)
            break;
    }

    // Electricity meter lookup
    switch(electricityMeterStatus){
        case "ready":
            electricityMeterLookup.find(".icon.spinning").addClass("show");
            electricityMeterLookup.find(".step-content").removeClass("open");

            getElectricityMeterLookup(energyLookupId).then(function(response){
                if(response.success){
                    // Update lookup steps
                    propertyLookupUpdateSteps(
                        response.data.energyLookup.gasAddressStatus,
                        response.data.energyLookup.gasAddressOptions,
                        response.data.energyLookup.gasMeterStatus,
                        response.data.energyLookup.gasMeterOptions,
                        response.data.energyLookup.electricityAddressStatus,
                        response.data.energyLookup.electricityAddressOptions,
                        response.data.energyLookup.electricityMeterStatus,
                        response.data.energyLookup.electricityMeterOptions
                    );
                } else {
                    alert("Error: Getting electricity meter lookup. Refresh page to continue.")
                }
            }).catch(function(error){
                alert("Error: looking up electricity meter")
            });
            electricityMeterLookup.find(".text .subtext").text("");
            break;
        case "option-selection":
            electricityMeterLookup.find(".icon.error").addClass("show");
            electricityMeterLookup.find(".step-content").addClass("open");

            // Clear options
            electricityMeterLookup.find(".step-content .address-selector-options").html("");

            // Show address selector
            electricityMeterLookup.find(".step-content .address-selector").removeClass("hidden");

            // Delete options
            electricityMeterLookup.find(".step-content .meter-selector-options").html("");

            // Loop through options
            for (let i = 0; i < electricityMeterOptions.length; i++) {
                let meter = electricityMeterOptions[i];

                // Create option
                let option = $(`<div class='meter-selector-option'><input type='radio' id='meterSelectorOption${meter.uuid}' name='meterSelectorOption' value='${meter.uuid}'><label for="meterSelectorOption${meter.uuid}"><span class="meter-index">Meter #${meter.index}</span><div class="content"><div class="consumption-figure">${meter.consumption} kWh</div><div class="description">${meter.designation} - ${meter.supplier}</div></div></label></div>`);

                // Add option to options
                electricityMeterLookup.find(".step-content .meter-selector-options").append(option);
            }

            // If only one option, select it
            if (electricityMeterOptions.length === 1){
                // Select option
                electricityMeterLookup.find(".meter-selector-option input[type=radio]").prop("checked", true);

                // Enable button
                electricityMeterLookup.find(".confirm-energy-lookup-fuel-meter-selection").removeAttr("disabled");
            }

            // Show options
            electricityMeterLookup.find(".step-content").addClass("open");
            electricityMeterLookup.find(".text .subtext").text("Select an option");
            break;
        case "error":
            electricityMeterLookup.find(".icon.error").addClass("show");
            electricityMeterLookup.find(".step-content").removeClass("open");
            electricityMeterLookup.find(".text .subtext").text("There was a problem with this step");
            break;
        case "waiting":
            electricityMeterLookup.find(".icon.waiting").addClass("show");
            electricityMeterLookup.find(".step-content").removeClass("open");
            electricityMeterLookup.find(".text .subtext").text("");
            break;
        case "skipped":
            electricityMeterLookup.find(".icon.skipped").addClass("show");
            electricityMeterLookup.find(".step-content").removeClass("open");
            electricityMeterLookup.find(".text .subtext").text("Step skipped");

            // Wait 1 second
            setTimeout(function(){

                // Close modal
                $(".modal.property-energy-lookup").removeClass("open");

                // Change page
                changePage('property-details');
            }, 2500);
            break;
        case "none-found":
            electricityMeterLookup.find(".icon.skipped").addClass("show");
            electricityMeterLookup.find(".step-content").removeClass("open");
            electricityMeterLookup.find(".text .subtext").text("No meters found at address");
            break;
        case "complete":
            electricityMeterLookup.find(".icon.success").addClass("show");
            electricityMeterLookup.find(".step-content").removeClass("open");
            electricityMeterLookup.find(".text .subtext").text("Step complete");

            // Wait 1 second
            setTimeout(function(){

                // Close modal
                $(".modal.property-energy-lookup").removeClass("open");

                // Change page
                changePage('property-details');
            }, 1000);
            break;
        default:
            console.log("Error: Unknown electricity meter lookup status", electricityMeterLookup)
            break;
    }
}

function propertyLookupInputFormCheckboxChange(){
    const propertyPostcode = $(".modal.property-energy-lookup #energy-lookup-property-postcode");
    const propertyOwnersName = $(".modal.property-energy-lookup #energy-lookup-property-owners-name");
    const consentCheckbox = $(".modal.property-energy-lookup #energy-lookup-property-owners-consent-checkbox");
    const termsAgreeCheckbox = $(".modal.property-energy-lookup #energy-lookup-terms-agree-checkbox");

    if(consentCheckbox.is(":checked") && termsAgreeCheckbox.is(":checked") && propertyPostcode.val() !== '' && propertyOwnersName.val() !== ''){
        $(".modal.property-energy-lookup .input-page .confirm-button").removeAttr("disabled");
    } else {
        $(".modal.property-energy-lookup .input-page .confirm-button").attr("disabled", true);
    }
}

function validateManualPropertyEnergyForm(){
    let hasError = false;

    // Elements
    const page = $(".property-energy-form .manual-property-energy");
    const saveButton = page.find(".save-manual-property-energy-data");

    // Form values
    const assessmentStartDate = page.find("#manual-energy-assessment-period-start").val();
    const assessmentEndDate = page.find("#manual-energy-assessment-period-end").val();
    const electricityConsumption = page.find("#manual-energy-electricity-consumption").val();
    const heatingConsumption = page.find("#manual-energy-heating-consumption").val();
    const heatingFuelType = page.find("#manual-energy-heating-fuel-type .selected").data('value');

    // Make sure assessment start and end date is not empty
    if(assessmentStartDate=== '' || assessmentEndDate === ''){
        hasError = true;
    } else {
        // Make sure total assessment period is exactly 12 months
        let startDate = new Date(assessmentStartDate);
        let endDate = new Date(assessmentEndDate);
    }

    // Make sure electricity and gas consumption is not empty
    if(electricityConsumption === '' || heatingConsumption === ''){
        hasError = true;
    }

    // Make sure heating fuel type is not empty
    if(heatingFuelType === undefined || heatingFuelType === ''){
        hasError = true;
    }

    // Enable or disable button
    if(hasError){
        saveButton.attr('disabled', true);
    } else {
        saveButton.removeAttr('disabled');
    }
}

function validateManualPropertyDetailsForm(){
    let hasError = false;

    // Elements
    const page = $(".property-energy-form .manual-property-details");
    const saveButton = page.find(".save-manual-building-data");

    // Form values
    const addressLine1 = page.find("#manual-address-line-1").val();
    const addressLine2 = page.find("#manual-address-line-2").val();
    const addressCity = page.find("#manual-address-city").val();
    const addressPostcode = page.find("#manual-address-postcode").val();
    const floorArea = page.find("#manual-floor-area").val();
    const buildingType = page.find("#manual-building-type .selected").data('value');

    // Make sure address line 1 is not empty
    if(addressLine1 === ''){
        hasError = true;
    }

    // Make sure address city is not empty
    if(addressCity === ''){
        hasError = true;
    }

    // Make sure address postcode is not empty
    if(addressPostcode === ''){
        hasError = true;
    }

    // Make sure floor area is not empty
    if(floorArea === ''){
        hasError = true;
    }

    // Make sure building type is not empty
    if(buildingType === undefined || buildingType === ''){
        hasError = true;
    }

    // Enable or disable button
    if(hasError){
        saveButton.attr('disabled', true);
    } else {
        saveButton.removeAttr('disabled');
    }
}

