let body = $('body');
import Swiper, {Manipulation} from 'swiper';
import {centerFormProgressV2ActiveStage, checkTechnologyData, loadTechnologyPage} from './functions.js';


//Document ready
$(document).ready(function(){
    let currentPage = $("#currentPage").val();

    //Make sure on technologies page
    if(currentPage === "technologies"){
        //Initiate technology slider
        if($(".slide-container").length > 0){
            let technologySlider = new Swiper('.slide-container', {
                slidesPerView: "auto",
                spaceBetween: 14,
                navigation: {
                    nextEl: ".next-slide",
                    prevEl: ".previous-slide",
                },
                modules: [Manipulation]
            });
        }

        //Highlight current technology's nav item
        $('.technology-nav .slide-container .swiper-slide').first().children(".item").addClass("active");

        //Load technology page
        loadTechnologyPage($('.technology-nav .slide-container .swiper-slide').first().children(".item").data("technology"));

        //Check technology page inputs
        checkTechnologyData();
    }
});





//#region Technology Navigation

//Input tabs navigation
body.on('click', '.module-form .form-progress-v2 .stages .stage-button', function(e) {
    //Check to see if stage is disabled
    if($(this).hasClass("disabled")) return;

    let currentPage = $(".module-form .form-pages").data("current-page");
    if(currentPage > $(this).data("page")){
        moduleFormChangePage($(this).data("page"));
    } else {
        moduleFormInputPageChange($(this).data("page"));
    }
});

//Scroll tabs navigation
body.on('click', '.module-form .form-progress-v2 .navigation-button', function(e) {
    let scrollPosition = 0

    //Get stage buttons
    const stageButtons = $(".form-progress-v2 .stages .stage-button")

    //Get stages container
    const stagesContainer = stageButtons.closest(".stages");


    //Get direction
    const direction = $(this).data("direction");

    //Scroll amount
    const scrollAmount = stageButtons.width() * 2;

    //New scroll position
    if(direction === "right"){
        scrollPosition = stagesContainer.scrollLeft() + scrollAmount;
    } else {
        scrollPosition = stagesContainer.scrollLeft() - scrollAmount;
    }

    //Check to see page can be moved in direction
    stagesContainer.animate({
        scrollLeft: scrollPosition
    }, 500);
});

body.on('change', '.module-form .form-pages .page .input-container .input-form .field .toggled-fields-checkbox', function(e) {
    const fieldsName = $(this).data("fields-name");
    let fieldsElement = $(this).closest('.module-form .form-pages .page .input-container .input-form').find('.toggled-fields[data-fields-name="' + fieldsName + '"]');

    if($(this).find("input").prop("checked")){
        fieldsElement.show();
    } else {
        fieldsElement.hide();
    }
});

body.on('change', '.module-form .form-pages .page .input-container .input-form .field .toggled-fields-radio input', function(e) {
    const fieldsName = $(this).closest(".toggled-fields-radio").data("fields-name");
    const fieldsValue = $(this).val();

    //Hide selected fields
    $(this).closest('.module-form .form-pages .page .input-container .input-form').find('.toggled-fields[data-fields-name="' + fieldsName + '"]').hide();

    //Selected fields
    $(this).closest('.module-form .form-pages .page .input-container .input-form').find('.toggled-fields[data-fields-name="' + fieldsName + '"][data-fields-value="' + fieldsValue + '"]').show();
});

body.on('click', '.module-form .form-pages .page .input-container .input-form .field .dropdown.toggled-fields-dropdown .dropdown-content ul li', function(e) {
    //Get values
    const dropdownName = $(this).closest(".toggled-fields-dropdown").data("dropdown-name");
    const dropdownValue = $(this).text();

    //Get fields element
    let selectFieldsElement = $(this).closest('.module-form .form-pages .page .input-container .input-form').find('.dropdown-toggled-fields[data-dropdown-name="' + dropdownName + '"][data-dropdown-value="' + dropdownValue + '"]');
    let nonSelectFieldsElement = $(this).closest('.module-form .form-pages .page .input-container .input-form').find('.dropdown-toggled-fields[data-dropdown-name="' + dropdownName + '"]').not('[data-dropdown-value="' + dropdownValue + '"]');

    //Hide all non-select fields
    nonSelectFieldsElement.hide();

    //Show select fields
    selectFieldsElement.show();
});

body.on('input', '.module-form .form-pages .page .input-container .input-form .field .slider-input .sliderContainer input[type=range]', function(e) {
    let value = $(this).val();
    $(this).closest(".slider-input").find(".sliderValue").text(value + "%");
});

//Select technology from pop-up menu
body.on('click', '.menu .menu-content .menu-item', function(e) {
    //Get technology slider
    let technologySlider = document.querySelector('.slide-container').swiper;

    //Toggle active style to nav item
    $(this).toggleClass("active");

    if($(this).hasClass("active")){
        if(typeof technologySlider !== "undefined" && technologySlider !== null){
            //Add technology nav item to slider nav
            switch($(this).data("technology")){
                case 1:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="1"><img src="assets/img/idea.svg" alt=""><h2>Energy Efficient Lighting</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 2:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="2"><img src="assets/img/radiator.svg" alt=""><h2>Heat Transfer Fluid</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 3:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="3"><img src="assets/img/boiler.svg" alt=""><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button><h2>Boiler Replacement</h2></div></div>');
                    break;
                case 4:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="4"><img src="assets/img/fire.svg" alt="" style="transform: scale(0.85);"><h2>Boiler Management Unit</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 5:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="5"><img src="assets/img/chp.svg" alt=""><h2>CHP</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 6:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="6"><img src="assets/img/solar-panel.svg" alt=""><h2>Solar PV</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 7:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="7"><img src="assets/img/insulation.svg" alt=""><h2>Roof Insulation</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 8:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="8"><img src="assets/img/heatpump.svg" alt=""><h2>Heat Pump</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 9:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="9"><img src="assets/img/heatpump.svg" alt=""><h2>Commercial Heat Pump</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 10:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="10"><img src="assets/img/refrigerator.svg" alt=""><h2>Refrigeration</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 11:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="11"><img src="assets/img/energy-monitoring.svg" alt="" style="padding: 6px;"><h2>Energy Monitoring</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 12:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="12"><img src="assets/img/valve.png" alt=""><h2>Smart TRV</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 13:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="13"><img src="assets/img/air-conditioning.svg" alt=""><h2>Smart AC Control Units</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 14:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="14"><img src="assets/img/high-voltage.svg" alt=""><h2>Voltage Optimisation</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 15:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="15"><img src="assets/img/water-heater.svg" alt=""><h2>Gas Fired Water Heater Control</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 16:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="16"><img src="assets/img/insulation.svg" alt=""><h2>Building Fabric</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
                case 17:
                    technologySlider.appendSlide('<div class="swiper-slide"><div class="item" data-technology="17"><img src="assets/img/hot-water-timer.svg" alt=""><h2>Hot Water Timer</h2><button class="removeTechnology"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div></div>');
                    break;
            }
        }

        //If item is only selected technology, load it's page
        if($('.technology-nav .slide-container .item').length === 1){
            //Load technology page
            loadTechnologyPage($(this).data("technology"));

            //Mark technology as active on slider nav
            $(".technology-nav .swiper-slide").first().children(".item").addClass("active");
        }

        //Add technology to session list of selected modules server-side
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: {
                data: JSON.stringify({
                    action: "addTechnology",
                    technology: $(this).data("technology")
                })
            },
            success: function(){
                checkTechnologyData();
            }
        });

    } else {
        //Selected technology slider nav item
        let technologyNavItem = $(".technology-nav .slide-container .swiper-slide .item[data-technology=" + $(this).data("technology") + "]");

        //Remove technology nav item from slider nav
        technologyNavItem.parent().remove();

        //Technology is the current page
        if(technologyNavItem.hasClass("active")){
            let firstTechnologyNavItem = $(".technology-nav .slide-container .swiper-slide").first().children(".item");

            //Switch page to the first technology item
            firstTechnologyNavItem.addClass("active");

            //Load first technology page
            loadTechnologyPage(firstTechnologyNavItem.data("technology"));
        }

        //Get technology slider nav
        let technologyNavItems = $('.technology-nav .slide-container .item');

        //Show empty page if no technologies have been selected
        if (technologyNavItems.length < 1){
            loadTechnologyPage(-1);
        }

        //Remove technology from session list of selected modules server-side
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: {
                data: JSON.stringify({
                    action: "removeTechnology",
                    technology: $(this).data("technology")
                })
            },
            success: function(){
                checkTechnologyData();
            }
        });

    }
    e.stopPropagation();
});

//Stop technology pop-up menu closing if it's container clicked
body.on('click', '.menu .menu-content', function(e) {
    e.stopPropagation();
});

//Toggle technology pop-up menu
body.on('click', '.menu .selector, #noTechModuleSelector', function(e) {
    $(".technology-nav .menu .menu-content").toggleClass("open");
    e.stopPropagation();
});

//Previous technology button
body.on('click', '.technology-nav .previous-slide', function(e) {
    let navItems = $('.technology-nav .slide-container');
    let currentPos = navItems.scrollLeft();
    navItems.animate({
        scrollLeft: currentPos - 130
    }, 150);
});

//Next technology button
body.on('click', '.technology-nav .next-slide', function(e) {
    let navItems = $('.technology-nav .slide-container');
    let currentPos = navItems.scrollLeft();
    navItems.animate({
        scrollLeft: currentPos + 130
    }, 150);
});

//Remove technology via close button of technology nav slider
body.on('click', '.technology-nav .removeTechnology', function(e) {
    let item = $(this).parent(".item");

    //Remove item from nav
    $(this).closest(".swiper-slide").remove();

    //Unselect item from selector menu
    $(".technology-nav .menu .menu-content .menu-item.active[data-technology=" + item.data("technology") + "]").removeClass("active");

    let firstTechnologyNavItem = $(".technology-nav .slide-container .swiper-slide").first().children(".item");

    //Switch page to the first technology item
    firstTechnologyNavItem.addClass("active");

    //Load first technology page
    loadTechnologyPage(firstTechnologyNavItem.data("technology"));

    //Get technology slider nav
    let technologyNavItems = $('.technology-nav .slide-container .item');

    //Show empty page if no technologies have been selected
    if (technologyNavItems.length < 1){
        loadTechnologyPage(-1);
    }

    //Remove the technology to session server-side
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "removeTechnology",
                technology: item.data("technology")
            })
        },
        success: function(){
            checkTechnologyData();
        }
    });

});

//Switch technology page via technology slider nav click
body.on('click', '.technology-nav .item', function(e) {
    //Skip if a technology is already being loaded
    if($(".page-content .page-loader").length > 0) return;

    //Remove the existing active item from the technology slider nav
    $(".technology-nav .item.active").removeClass("active");

    //Activate the clicked item
    $(this).addClass("active");

    //Load the technology page
    loadTechnologyPage($(this).data("technology"));
});

// Go to technology
body.on('click', '.items .item', function(e) {
    //Skip if technology item is disabled
    if($(this).closest(".items").hasClass("disabled")) return;

    //Skip if link module
    if($(this).hasClass("link-module")) return;

    //Toggle the technology
    $(this).toggleClass("active");


    if($(this).hasClass("active")){
        //Add the technology to session server-side
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: {
                data: JSON.stringify({
                    action: "addTechnology",
                    technology: $(this).data("technology")
                })
            }
        });
    } else {
        //Remove the technology to session server-side
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: {
                data: JSON.stringify({
                    action: "removeTechnology",
                    technology: $(this).data("technology")
                })
            }
        });
    }

    if($(".items .item.active").length < 1){
        //Show error message
        $(".actions-bar-container .actions-bottom-bar .message-bar").removeClass("hide");

        //Disable next button
        $(".actions-bar-container .actions-bottom-bar .next").prop("disabled", true);
    } else {
        //Hide error message
        $(".actions-bar-container .actions-bottom-bar .message-bar").addClass("hide");

        //Enable next button
        $(".actions-bar-container .actions-bottom-bar .next").removeAttr('disabled');
    }
});


//#endregion