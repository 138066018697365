const body = $('body');

body.on('click', '.wtp-debug-section .wtp-debug-section-header .wtp-debug-collapse-button', function(e) {
    e.preventDefault();
    $(this).closest('.wtp-debug-section').toggleClass('open');
});


body.on('click', '.wtp-debug-show-working-button', function(e) {
    e.preventDefault();
    $(this).closest('.wtp-debug-calculation').toggleClass('show-working');
});
